import { Component, OnInit, NgZone, OnDestroy, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { OrganizationService } from 'src/app/_services/generatedServices';
import { CreatePaymentMethodViewModel, CreateSubscriptionViewModel, OrganizationBillingViewModel, PricingTierViewModel, RunDnaProduct, ValidateCouponViewModel } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'add-card',
  templateUrl: 'activate-subscription.component.html',
})
export class ActivateSubscription implements OnInit
{
  tiers: PricingTierViewModel;
  constructor(public fb: FormBuilder, public toastr: ToasterService, public activeModal: NgbActiveModal, public zone: NgZone, public orgService: OrganizationService,
    private auth: AuthenticationService) { }

  initialized = false;
  saving = false;
  contactForm: any;
  errorMessage: '';
  useCardOnFile: boolean = false;
  brandKeyName: string;

  coupon: string;
  couponDescription: string;
  couponDuration: string;
  couponDurationDescription: string;
  isCouponValid: boolean = false;
  couponSubject: Subject<ValidateCouponViewModel> = new Subject<ValidateCouponViewModel>();

  RunDnaProduct = RunDnaProduct;
  pricingIsBeingRecalculated: boolean = false;

  @Input()
  organization: OrganizationBillingViewModel;

  ngOnInit(): void
  {
    this.orgService.getPricingTiers(this.coupon ? this.coupon : '').subscribe((tiers) =>
    {
      this.tiers = tiers;
      if (this.organization.last4OfCard && this.organization.stripeCustomerId) {
        this.useCardOnFile = true;
      }

      this.contactForm = this.fb.group({
        isMonthly: [true, [Validators.required]],
        cardNumber: ['', (this.useCardOnFile ? null : Validators.required)],
        expMo: ['', (this.useCardOnFile ? null : Validators.required)],
        expYr: ['', (this.useCardOnFile ? null : Validators.required)],
        cvv: ['', (this.useCardOnFile ? null : Validators.required)],
        coupon: [this.coupon]
      });

      this.initialized = true;
    });

   
    this.couponSubject.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((model: ValidateCouponViewModel) =>
      {
        this.coupon = model.coupon;

        if (!model.coupon) {
          this.isCouponValid = false;
          this.couponDescription = '';
          this.couponDurationDescription = '';
          this.couponDuration = '';

          this.orgService.getPricingTiers('').subscribe((tiers) =>
          {
            this.tiers = tiers;
            this.pricingIsBeingRecalculated = false;
          });

          return;
        }

        this.orgService.validateCoupon(model).subscribe(
          (couponVewModel) =>
          {
            this.couponDescription = couponVewModel.description;
            this.couponDurationDescription = couponVewModel.durationDescription;


            this.isCouponValid = couponVewModel.isValid;
            this.couponDuration = couponVewModel.duration;

            if (this.isCouponValid) {

              if (this.organization.complimentarySubscription && this.couponDuration == 'repeating') {
                this.toastr.confirmModal('<p class=\"text-danger font-weight-400\">Please note, you currently have an active complimentary subscription, if you apply a coupon now, it will take effect immediately, potentially resulting in a partial loss of the coupon\'s benefits.<br/>Therefore, it is recommended to apply the coupon at the end of the complimentary subscription period.</p>', "Coupon", "Proceed purchase", "Dont Apply").subscribe(result =>
                {
                  if (result === false) {
                    this.contactForm.patchValue({ 'coupon': '' });

                    this.validateCoupon();
                    return;
                  }
                });
              }
            }

            this.orgService.getPricingTiers((model.coupon && this.isCouponValid) ? model.coupon : '').subscribe((tiers) =>
            {
              this.tiers = tiers;
              this.pricingIsBeingRecalculated = false;
            });
          },
          (error) =>
          {
            this.couponDescription = error.error[0];
            this.couponDurationDescription = '';
            this.couponDuration = '';
            this.isCouponValid = false;

            this.orgService.getPricingTiers('').subscribe((tiers) =>
            {
              this.tiers = tiers;
              this.pricingIsBeingRecalculated = false;
            });

          }
        );
      });
  }

  onUseCardOnFileChange(event: any)
  {
    if (this.useCardOnFile) {
      this.contactForm.get('cardNumber').setValidators([]);
      this.contactForm.get('expMo').setValidators([]);
      this.contactForm.get('expYr').setValidators([]);
      this.contactForm.get('cvv').setValidators([]);
    } else {
      this.contactForm.get('cardNumber').setValidators([Validators.required]);
      this.contactForm.get('expMo').setValidators([Validators.required]);
      this.contactForm.get('expYr').setValidators([Validators.required]);
      this.contactForm.get('cvv').setValidators([Validators.required]);
    }

    this.contactForm.get('cardNumber').updateValueAndValidity();
    this.contactForm.get('expMo').updateValueAndValidity();
    this.contactForm.get('expYr').updateValueAndValidity();
    this.contactForm.get('cvv').updateValueAndValidity();
  }

  save(): void
  {
    if (!this.contactForm.valid) {
      this.contactForm.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    var confirmationText = 'A subscription will be created and your account will be charged immediately. Are you sure you want to proceed?';


    if (this.organization.complimentarySubscription) {
      confirmationText = 'A subscription will be created and your account will be charged at the end of the Complimentary Subscription. Are you sure you want to proceed?';
    }

    this.toastr.confirmModal(confirmationText, 'Purchase', 'Purchase', 'Cancel').subscribe(result =>
    {
      if (result) {
        this.brandKeyName = this.auth.user.organizationBrandKeyName;

        if (this.useCardOnFile) {
          this.createSubscription();
        } else {
          this.createPaymentAndSubscription();
        }
      }

      return;
    });
  }

  createSubscription()
  {
    this.saving = true;
    let model = new CreateSubscriptionViewModel();
    model.isMonthly = this.contactForm.controls.isMonthly.value;
    model.brandKeyName = this.brandKeyName;
    model.coupon = this.contactForm.controls.coupon.value;
    console.log(model);
    this.orgService.createSubscriptionWithExistingCard(model).subscribe(
      (company) =>
      {
        this.activeModal.close(company);
        this.saving = false;
      },
      (error) =>
      {
        this.errorMessage = error.error[0];
        this.saving = false;
      }
    );
  }

  createPaymentAndSubscription()
  {
    this.saving = true;
    let card = { number: this.contactForm.controls.cardNumber.value, exp_month: this.contactForm.controls.expMo.value, exp_year: this.contactForm.controls.expYr.value, cvc: this.contactForm.controls.cvv.value };
    (<any>window).Stripe.card.createToken(card, (status: number, response: any) =>
    {
      if (status === 200) {
        console.log(response.id);
        let model = new CreatePaymentMethodViewModel();
        model.stripeToken = response.id;
        model.last4 = response.card.last4;
        model.cardType = response.card.brand;
        model.isMonthly = this.contactForm.controls.isMonthly.value;
        model.brandKeyName = this.brandKeyName;
        model.coupon = this.contactForm.controls.coupon.value;
        this.zone.run(() =>
        {
          this.orgService.addPaymentMethodAndCreateSubscription(model).subscribe(
            (company) =>
            {
              this.activeModal.close(company);
              this.saving = false;
            },
            (error) =>
            {
              this.errorMessage = error.error[0];
              this.saving = false;
            }
          );
        });
      } else {
        this.errorMessage = response.error.message;
        this.saving = false;
        console.error(response.error.message);
      }
    });
  }


  getPricingDetails()
  {
    let description = 'Price: ';

    if (this.contactForm.controls['isMonthly'].value) {
      description += " " + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.tiers.monthlyPriceNextInvoice);

      if (this.isCouponValid && this.tiers.monthlyPriceNextInvoice != this.tiers.monthlyPriceLongTerm) {
        description += ' ' + this.couponDurationDescription + " then " + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.tiers.monthlyPriceLongTerm) + "/month";
      } else {
        description += "/month";
      }
    }
    else {
      description += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.tiers.yearlyPriceNextInvoice);

      if (this.isCouponValid && this.tiers.yearlyPriceNextInvoice != this.tiers.yearlyPriceLongTerm) {
        description += ' ' + this.couponDurationDescription + " then " + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.tiers.yearlyPriceLongTerm) + "/year";
      } else {
        description += "/year";
      }
    }

    return description;
  }

  onChangeIsMonthly()
  {
    this.validateCoupon();
  }

  validateCoupon()
  {
    this.pricingIsBeingRecalculated = true;

    let model = new ValidateCouponViewModel();
    model.coupon = this.contactForm.controls.coupon.value;
    model.isMonthlyPrice = this.contactForm.controls.isMonthly.value;

    this.couponSubject.next(model);
  }

  searchClients() {
    throw new Error('Method not implemented.');
  }
  get f() {
    return this.contactForm.controls;
  }
}
