//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class AddUserViewModel
{
	public email: string;
	public firstName: string;
	public lastName: string;
	public userRole: UserRole;
	public teams: TeamUserViewModel[];
}
export class UpdateUserViewModel
{
	public userRole: UserRole;
	public teams: TeamUserViewModel[];
}
export class UpdateUserRoleViewModel
{
	public userRole: UserRole;
}
export class StravaDeauthorizeResponse
{
	public accessToken: string;
}
export class AssessmentCategoryResultViewModel
{
	public category: AssessmentItemViewModel;
	public subCategories: AssessmentItemViewModel[];
}
export class AssessmentItemViewModel
{
	public name: string;
	public assessmentResult: AssessmentResult;
	public assessmentResult2?: AssessmentResult;
}
export class AssessmentListViewModelRead
{
	public id: number;
	public organizationId: number;
	public assessmentType: AssessmentType;
	public assessmentDate: Date;
	public movementCategory?: MovementCategory;
	public gaitCapabilityCategory?: GaitCapabilityCategory;
	public walkingCategory?: WalkingCategory;
	public score: string;
	public runningCategory: string;
	public status?: AssessmentStatus;
	public name: string;
}
export class AssessmentProgramCategoryViewModel
{
	public assessmentId: number;
	public handicapScore: number;
	public impairmentCategory?: ImpairmentCategory;
	public impairmentCategoryProgramId?: number;
	public impairmentCategoryProgramName: string;
	public movementCategory: MovementCategory;
	public movementCategoryProgramId?: number;
	public movementCategoryProgramName: string;
	public clientUserId: number;
	public clientFullName: string;
}
export class AssessmentTooltipViewModel
{
	public title: string;
	public description: string;
	public videoLink: string;
}
export class AssessmentTooltipViewModelRead extends AssessmentTooltipViewModel
{
	public id: number;
	public key: string;
}
export class GaitAnalysisViewModel
{
	public id: number;
	public userId?: number;
	public user: UserViewModel;
	public height: string;
	public weight: number;
	public birthdate: Date;
	public runningCategory: string;
	public firstname: string;
	public gender: string;
	public lastname: string;
	public uniqueid: string;
	public milesPerWeek: number;
	public primaryComplaint: string;
	public primaryComplaintSide: string;
	public secondaryComplaint: string;
	public yearsExperienceRunning: number;
	public name: string;
	public tag: string;
	public assetPath: string;
	public streamingLocatorName: string;
	public date: Date;
	public time: any;
	public protocolId: string;
	public cadence: string;
	public verticalOscillation: string;
	public groundContactTime: SideViewModel;
	public legStiffness: SideViewModel;
	public tibialInclination: SideViewModel;
	public footInclination: SideViewModel;
	public strikeFromCom: SideViewModel;
	public crossover: SideViewModel;
	public ankleDorsiflexionPlantarflexion: SideViewModel;
	public footPronationSupination: SideViewModel;
	public peakKneeFlexion: SideViewModel;
	public peakHipAdd: SideViewModel;
	public peakIr: SideViewModel;
	public contralateralPelvicDrop: SideViewModel;
	public forwardLeanAngle: SideViewModel;
	public hipExtension: SideViewModel;
	public ankleSupination: SideViewModel;
	public hipFlexion: SideViewModel;
	public frontSideStance: SideViewModel;
	public dutyFactor: SideViewModel;
	public notes: string;
	public shoeYear?: number;
	public shoeManufacturer: string;
	public shoeModel: string;
	public uniqueGuidId: any;
}
export class GaitAnalysisSummaryViewModel
{
	public id: number;
	public name: string;
	public notes: string;
}
export class SideViewModel
{
	public left: string;
	public right: string;
}
export class AssessmentSummaryDTO
{
	public id: number;
	public dateTime: Date;
	public impairmentCategory?: ImpairmentCategory;
	public movementCategory: MovementCategory;
	public status: AssessmentStatus;
	public handicapScore: number;
}
export class CreateAssessmentDTO
{
	public impairmentCategory?: ImpairmentCategory;
	public toeTouch: AssessmentResult;
	public toeTouchStraighLegRaiseLeft: AssessmentResult;
	public toeTouchStraighLegRaiseRight: AssessmentResult;
	public toeTouchChildPose: AssessmentResult;
	public backBend: AssessmentResult;
	public backBendProneHipExtensionLeft: AssessmentResult;
	public backBendProneHipExtensionRight: AssessmentResult;
	public backBendPronePressUp: AssessmentResult;
	public rotationLeft: AssessmentResult;
	public rotationRight: AssessmentResult;
	public rotationQuadrupedRotationLeft: AssessmentResult;
	public rotationQuadrupedRotationRight: AssessmentResult;
	public rotationProneHipRotationLeft: AssessmentResult;
	public rotationProneHipRotationRight: AssessmentResult;
	public rotationProneHipExternalRotationLeft: AssessmentResult;
	public rotationProneHipExternalRotationRight: AssessmentResult;
	public rotationProneHipInternalRotationLeft: AssessmentResult;
	public rotationProneHipInternalRotationRight: AssessmentResult;
	public singleLegBalanceLeft: AssessmentResult;
	public singleLegBalanceRight: AssessmentResult;
	public singleLegBalanceHalfKneelLeft: AssessmentResult;
	public singleLegBalanceHalfKneelRight: AssessmentResult;
	public singleLegBalanceFootAssessment: AssessmentResult;
	public squat: AssessmentResult;
	public squatDKTCLeft: AssessmentResult;
	public squatDKTCRight: AssessmentResult;
	public squatLungeDorsiflexionLeft: AssessmentResult;
	public squatLungeDorsiflexionRight: AssessmentResult;
	public singleLegSitToStandLeft: AssessmentResult;
	public singleLegSitToStandRight: AssessmentResult;
	public singleLegCalfRaiseLeft: AssessmentResult;
	public singleLegCalfRaiseRight: AssessmentResult;
	public sidePlankLeft: AssessmentResult;
	public sidePlankRight: AssessmentResult;
	public unilateralHipBridgeEnduranceLeft: AssessmentResult;
	public unilateralHipBridgeEnduranceRight: AssessmentResult;
	public toeSplayLeft: AssessmentResult;
	public toeSplayRight: AssessmentResult;
	public halluxExtensionLeft: AssessmentResult;
	public halluxExtensionRight: AssessmentResult;
	public halluxExtensionAbductionLeft: AssessmentResult;
	public halluxExtensionAbductionRight: AssessmentResult;
	public ankleInversionLeft: AssessmentResult;
	public ankleInversionRight: AssessmentResult;
	public ankleEversionLeft: AssessmentResult;
	public ankleEversionRight: AssessmentResult;
	public notes: string;
}
export class AssessmentViewModelRead extends CreateAssessmentDTO
{
	public id: number;
	public dateTime: Date;
}
export class GaitCapabilityAssessmentProgramCategoryViewModel
{
	public assessmentId: number;
	public handicapScore: number;
	public gaitCapabilityCategory?: GaitCapabilityCategory;
	public gaitCapabilityProgramId?: number;
	public gaitCapabilityProgramName: string;
	public clientUserId: number;
	public clientFullName: string;
}
export class GaitCapabilityAssessmentBaseDTO
{
	public handicapScore: number;
	public handicapScoreLevel: number;
	public mobility: GaitReportMobilityDTO;
	public stability: GaitReportStabilityDTO;
	public foot: GaitReportFootDTO;
	public functional: GaitReportFunctionalDTO;
}
export class GaitCapabilityAssessmentDTO
{
	public user: UserViewModel;
	public coach: UserViewModel;
	public dateTime: Date;
	public gaitCapabilityCategory: GaitCapabilityCategory;
	public status: AssessmentStatus;
	public program: ProgramListViewModelRead;
	public gaitCapabilityAssessmentBase: GaitCapabilityAssessmentBaseDTO;
	public name: string;
	public notes: string;
}
export class GaitReportMobilityDTO
{
	public scoreMax: number;
	public score: number;
	public ankleLeft: AssessmentResultShort;
	public ankleRight: AssessmentResultShort;
	public hipLeft: AssessmentResultShort;
	public hipRight: AssessmentResultShort;
	public hamstringsLeft: AssessmentResultShort;
	public hamstringsRight: AssessmentResultShort;
}
export class GaitCapabilityAssessmentSummaryDTO
{
	public id: number;
	public dateTime: Date;
	public gaitCapabilityCategory: GaitCapabilityCategory;
	public status: AssessmentStatus;
	public handicapScore: number;
}
export class GaitReportStabilityDTO
{
	public scoreMax: number;
	public score: number;
	public coreLeft: AssessmentResultShort;
	public coreRight: AssessmentResultShort;
	public hip: AssessmentResultShort;
	public calfLeft: AssessmentResultShort;
	public calfRight: AssessmentResultShort;
	public footLeft: AssessmentResultShort;
	public footRight: AssessmentResultShort;
}
export class GaitReportFootDTO
{
	public scoreMax: number;
	public score: number;
	public bigToeLeft: AssessmentResultShort;
	public bigToeRight: AssessmentResultShort;
	public pronationLeft: AssessmentResultShort;
	public pronationRight: AssessmentResultShort;
	public splayLeft: AssessmentResultShort;
	public splayRight: AssessmentResultShort;
}
export class GaitReportFunctionalDTO
{
	public scoreMax: number;
	public score: number;
	public toeTouch: AssessmentResultShort;
	public rotationLeft: AssessmentResultShort;
	public rotationRight: AssessmentResultShort;
	public balanceLeft: AssessmentResultShort;
	public balanceRight: AssessmentResultShort;
	public hurdleLeft: AssessmentResultShort;
	public hurdleRight: AssessmentResultShort;
}
export class GaitCapabilityAssessmentViewModelRead extends GaitCapabilityAssessmentDTO
{
	public id: number;
	public uniqueGuidId: any;
}
export class GaitComparisonReportViewModels
{
	public olderGaitReport: GaitReportViewModel;
	public newerGaitReport: GaitReportViewModel;
	public cadence: CategorySingleSideComparisonViewModel;
	public strideLength: CategorySideComparisonViewModel;
	public groundContact: CategorySideComparisonViewModel;
	public dutyFactor: CategorySideComparisonViewModel;
	public doubleSupport: CategorySideComparisonViewModel;
	public singleSupport: CategorySideComparisonViewModel;
	public stepWidth: CategorySideComparisonViewModel;
	public stanceTime: CategorySideComparisonViewModel;
	public hipFlexion: CategorySideComparisonViewModel;
	public kneeFlexion: CategorySideComparisonViewModel;
	public ankleFlexion: CategorySideComparisonViewModel;
	public pelvicRotation: CategorySideComparisonViewModel;
	public trunkRotation: CategorySideComparisonViewModel;
	public kneeFlexionMs: CategorySideComparisonViewModel;
	public peakHipAdd: CategorySideComparisonViewModel;
	public peakHipIr: CategorySideComparisonViewModel;
	public peakAnklePronation: CategorySideComparisonViewModel;
	public peakPelvicDrop: CategorySideComparisonViewModel;
	public pelvicTilt: CategorySideComparisonViewModel;
	public peakFoot: CategorySideComparisonViewModel;
	public hipExtension: CategorySideComparisonViewModel;
	public ankleSupination: CategorySideComparisonViewModel;
}
export class CategorySideComparisonViewModel
{
	public leftDifferencePercentage: number;
	public leftDifferenceDirection: ComparisonDirectionEnum;
	public leftComparisonResult: ComparisonResultEnum;
	public rightDifferencePercentage: number;
	public rightDifferenceDirection: ComparisonDirectionEnum;
	public rightComparisonResult: ComparisonResultEnum;
}
export class CategorySingleSideComparisonViewModel
{
	public differencePercentage: number;
	public differenceDirection: ComparisonDirectionEnum;
	public comparisonResult: ComparisonResultEnum;
}
export class GaitReportViewModel
{
	public id: number;
	public guidId: any;
	public name: string;
	public assetPath: string;
	public streamingLocatorName: string;
	public encoded: boolean;
	public gaitCapabilityCategory: GaitCapabilityCategory;
	public walkingCategory: WalkingCategory;
	public primaryFactor: string;
	public gaitProgramPrimaryFactor: GaitProgramPrimaryFactor;
	public gscProgram: ProgramListViewModelRead;
	public user: UserViewModel;
	public dongleId: string;
	public reportDate: Date;
	public notes: string;
	public gaitScoreDescription: GaitScoreDescription;
	public gaitScore: number;
	public trunk: CategoryScoreSide;
	public pelvis: CategoryScoreSide;
	public hip: CategoryScoreSide;
	public knee: CategoryScoreSide;
	public foot: CategoryScoreSide;
	public strideLength: CategorySide;
	public groundContact: CategorySide;
	public dutyFactor: CategorySide;
	public doubleSupport: CategorySide;
	public singleSupport: CategorySide;
	public stepWidth: CategorySide;
	public gaitSpeed: CategorySingle;
	public cadence: CategorySingle;
	public isGaitCapabilityAssessed: boolean;
	public gaitCapabilityAssessmentBase: GaitCapabilityAssessmentBaseDTO;
	public stanceTime: CategorySide;
	public hipFlexion: CategorySide;
	public kneeFlexion: CategorySide;
	public ankleFlexion: CategorySide;
	public pelvicRotation: CategorySide;
	public trunkRotation: CategorySide;
	public kneeFlexionMs: CategorySide;
	public peakHipAdd: CategorySide;
	public peakHipIr: CategorySide;
	public peakAnklePronation: CategorySide;
	public peakPelvicDrop: CategorySide;
	public pelvicTilt: CategorySide;
	public peakFoot: CategorySide;
	public hipExtension: CategorySide;
	public ankleSupination: CategorySide;
	public diagrams: GaitReportDiagrams[];
	public Update(name: string, notes: string) : void { } 
	public UpdateNotes(notes: string) : void { } 
}
export class GaitReportDiagrams
{
	public category: string;
	public title: string;
	public url: string;
	public resizedUrl: string;
}
export class GaitReportSummaryViewModel
{
	public id: number;
	public name: string;
	public notes: string;
}
export class GaitReportComparisonViewModel
{
	public id: number;
	public name: string;
}
export class CategoryScoreSide
{
	public left: CategoryScore;
	public right: CategoryScore;
}
export class CategorySide
{
	public left: number;
	public deviationPointsLeft: number;
	public rawValueLeft: string;
	public leftScore: CategoryScore;
	public right: number;
	public rawValueRight: string;
	public deviationPointsRight: number;
	public rightScore: CategoryScore;
}
export class CategorySingle
{
	public value: number;
	public rawValue: string;
	public deviationPoints: number;
	public score: CategoryScore;
}
export class LoadingLevelsProgramDisplayViewModel
{
	public loadingLevelsAssessmentId: number;
	public levelAssigned?: number;
	public loadingLevelProgramId?: number;
	public loadingLevelProgramName: string;
	public clientUserId: number;
	public clientFullName: string;
}
export class TwoDimensionalAssessmentProgramDisplayViewModel
{
	public twoDimensionalAssessmentId: number;
	public impairmentCategory?: ImpairmentCategory;
	public impairmentCategoryProgramId?: number;
	public impairmentCategoryProgramName: string;
	public clientUserId: number;
	public clientFullName: string;
}
export class AzureBlobSASViewModel
{
	public containerName: string;
	public sasToken: string;
	public storageUri: string;
	public fileName: string;
	public uri: string;
}
export class CreatePaymentMethodViewModel
{
	public stripeToken: string;
	public last4: string;
	public cardType: string;
	public isMonthly: boolean;
	public brandKeyName: string;
	public coupon: string;
}
export class CreateSubscriptionViewModel
{
	public isMonthly: boolean;
	public brandKeyName: string;
	public coupon: string;
}
export class CouponViewModel
{
	public isValid: boolean;
	public couponId: string;
	public description: string;
	public duration: string;
	public durationDescription: string;
}
export class UpcomingPriceViewModel
{
	public priceDescription: string;
}
export class ValidateCouponViewModel
{
	public coupon: string;
	public isMonthlyPrice: boolean;
}
export class BrandViewModelRead
{
	public id: number;
	public keyName: string;
	public name: string;
	public bannerText: string;
	public bannerUrl: string;
	public signupImageName: string;
	public navImageName: string;
	public cssStyleName: string;
	public bannerTextExpirationDate?: Date;
}
export class CalendarDayViewModel
{
	public calendarDate: Date;
	public calendarType: CalendarType;
	public dayOfWeek: number;
	public dayOfMonth: number;
	public isToday: boolean;
	public isSelectedDay: boolean;
	public isCurrentMonth: boolean;
	public monthShortName: string;
	public events: CalendarEventViewModel[];
}
export class CalendarEventViewModel
{
	public eventId: number;
	public contextMenuOptions: CalendarContextMenuOptions;
	public workoutDate: Date;
	public workout: WorkoutListViewModelRead;
	public sortOrder: number;
	public isCompleted: boolean;
	public eventName: string;
	public eventType: ClientDayEventType;
	public taskDescription: string;
	public workoutName: string;
	public timeInSeconds?: number;
	public miles: number;
	public recurringEvent: RecurringEventViewModelRead;
	public coachNotes: string;
	public singleActivityType?: ActivityType;
	public activityTypeDurations: EventActivityTypeDurationViewModel[];
	public plannedActivityTypeDurations: EventActivityTypeDurationViewModel[];
	public quickWorkoutName: string;
	public quickWorkoutDescription: string;
	public quickWorkoutActivityType?: ActivityType;
	public quickWorkoutDuration?: number;
	public quickWorkoutDistance?: number;
	public ratePerceivedExertion?: number;
	public garminActivityId?: number;
	public activityTypeDescription: string;
	public stravaActivityId?: number;
	public isTeamEvent: boolean;
}
export class CalendarWeekViewModel
{
	public startDate: Date;
	public endDate: Date;
	public actualTrainingLoad?: number;
	public plannedTrainingLoad?: number;
	public trainingLoadTargetLow?: number;
	public trainingLoadTargetHigh?: number;
	public activityTypeDurations: CalendarWeekActivityTypeDurationViewModel[];
	public days: CalendarDayViewModel[];
}
export class CalendarWeekActivityTypeDurationViewModel
{
	public activityType: ActivityType;
	public plannedDuration: number;
	public plannedDistance: number;
	public plannedTrainingLoad: number;
	public actualDuration: number;
	public actualDistance: number;
	public actualTrainingLoad: number;
}
export class CopyCalendarWeekViewModel
{
	public originalWeekStartDate: Date;
	public copyToWeekStartDate: Date;
}
export class EventActivityTypeDurationViewModel
{
	public activityType: ActivityType;
	public duration: number;
	public distance?: number;
}
export class RecurringEventViewModel
{
	public startDate: Date;
	public frequency: RecurringEventFrequency;
	public interval: number;
	public byDay?: DayOfWeekFlag;
	public count?: number;
	public untilDate?: Date;
}
export class RecurringEventViewModelRead extends RecurringEventViewModel
{
	public id: number;
}
export class ChartViewModel
{
	public chartHeaders: string[];
	public chartData: any[][];
}
export class BasicChartViewModel
{
	public chartHeader: string;
	public chartData: BasicReportViewModelRead[];
}
export class ClientDashboardCombinedViewModelRead
{
	public userId: number;
	public userCredentialId: number;
	public firstName: string;
	public lastName: string;
	public fullName: string;
	public nextScheduledEvent?: Date;
	public completionPercentage?: number;
	public miles7Days: number;
	public timeInSeconds7Days: number;
	public acuteChronicWorkloadRatioPercentage?: number;
	public intensityInSeconds7Days: number;
	public averageIntensityInSecondsTrailing7to34Days: number;
	public acuteChronicHistory: any;
	public currentWeekTrainingLoadTargetData: TrainingLoadTargetViewModelRead;
	public currentWeekTrainingLoadRanges: BasicChartViewModel;
	public trainingLoadFiveWeeks: BasicChartViewModel;
}
export class ClientDetailsViewModelRead
{
	public userId: number;
	public userCredentialId: number;
	public firstName: string;
	public lastName: string;
	public fullName: string;
	public nextScheduledEvent?: Date;
	public completionPercentage?: number;
	public miles7Days: number;
	public timeInSeconds7Days: number;
	public currentImpairmentCategory?: ImpairmentCategory;
	public currentGaitCapabilityCategory?: GaitCapabilityCategory;
	public currentWalkingCategory?: WalkingCategory;
	public currentHandicapScore?: number;
	public lastDailyStatusDate?: Date;
	public percentChangeCompletionPercentage: number;
	public percentChangeMiles: number;
	public percentChangeTimeInSeconds: number;
	public acuteChronicWorkloadRatioPercentage?: number;
	public intensityInSeconds7Days: number;
	public intensityInSecondsTrailing7to34Days: number;
	public garminAccessToken: string;
	public coachUserId?: number;
	public lastScheduledWorkoutDate?: Date;
	public lastCompletedWorkoutDate?: Date;
	public showSportACWRWarning: boolean;
}
export class ClientFinishWorkoutViewModel
{
	public messageBody: string;
	public isMessageFromCoach: boolean;
	public isCompleted: boolean;
	public dateCompleted?: Date;
	public ratePerceivedExertion?: number;
	public activityTypeDurations: ClientProgramWorkoutDayActivityTypeDurationViewModel[];
}
export class ClientUpdateWorkoutRPEViewModel
{
	public ratePerceivedExertion?: number;
}
export class ClientAddWorkoutMessageViewModel
{
	public messageBody: string;
	public isMessageFromCoach: boolean;
}
export class ClientUpdateWorkoutDurationsViewModel
{
	public activityTypeDurations: ClientProgramWorkoutDayActivityTypeDurationViewModel[];
}
export class ClientAddWorkoutNoteViewModel
{
	public note: string;
}
export class ClientUpdateWorkoutExerciseProgressesViewModel
{
	public exerciseProgresses: ClientProgramWorkoutDayExerciseProgressViewModelRead[];
}
export class CredentialsViewModel
{
	public userName: string;
	public password: string;
}
export class DailyStatusListViewModel
{
	public id: number;
	public userCredentialId: number;
	public dailyStatusDate: Date;
	public ableToRun: boolean;
	public percentEffort: number;
	public hasPain: boolean;
	public isSick: boolean;
}
export class DailyStatusViewModel
{
	public userCredentialId: number;
	public dailyStatusDate: Date;
	public ableToRun: boolean;
	public runningInjury?: boolean;
	public percentEffort: number;
	public hasPain: boolean;
	public painType?: PainType;
	public changedDailyRoutine: boolean;
	public dailyStress?: number;
	public ateWell?: number;
	public slept?: number;
	public caffeineConsumption?: number;
	public waterConsumption?: number;
	public sexualActivity?: number;
	public meditation?: number;
	public exerciseAmount?: number;
	public exerciseIntensity?: number;
	public steps?: number;
	public travel?: number;
	public other: string;
	public hasMuscleSoreness: boolean;
	public alcoholConsumption?: number;
	public isSick: boolean;
	public notes: string;
}
export class DailyStatusViewModelRead extends DailyStatusViewModel
{
	public id: number;
	public userFullName: string;
}
export class DailyStatusIdViewModelRead
{
	public id: number;
}
export class ClientExerciseViewModel
{
	public exerciseId: number;
	public clientUserId: number;
	public assignedByUserId?: number;
}
export class ClientExerciseViewModelRead extends ClientExerciseViewModel
{
	public exercise: ExerciseListViewModelRead;
}
export class LevelChangeClientExerciseViewModel
{
	public originalExerciseId: number;
	public clientUserId: number;
	public newExerciseId: number;
}
export class ClientWorkoutByCategoryViewModelRead
{
	public workoutCategory: WorkoutCategory;
	public workouts: WorkoutListViewModelRead[];
}
export class ClientWorkoutViewModel
{
	public workoutId: number;
	public clientUserId: number;
	public assignedByUserId?: number;
}
export class ClientWorkoutViewModelRead extends ClientWorkoutViewModel
{
	public workout: WorkoutListViewModelRead;
}
export class ExerciseEquipmentExerciseViewModel
{
	public exerciseEquipment: ExerciseEquipment;
	public exerciseId: number;
}
export class ExerciseListViewModelRead
{
	public id: number;
	public name: string;
	public organizationId?: number;
	public userFullName: string;
	public exerciseTags: ExerciseTagViewModelRead[];
	public workoutItemExerciseCount: number;
	public inExerciseProgression: boolean;
}
export class ExerciseProgressionLevelViewModel
{
	public level: number;
	public exerciseId: number;
}
export class ExerciseProgressionLevelViewModelRead extends ExerciseProgressionLevelViewModel
{
	public id: number;
	public exerciseProgressionId: number;
	public exerciseName: string;
}
export class ExerciseProgressionListViewModelRead
{
	public id: number;
	public name: string;
	public organizationId?: number;
	public userFullName: string;
	public notes: string;
}
export class ExerciseProgressionViewModel
{
	public name: string;
	public organizationId?: number;
	public userId?: number;
	public notes: string;
	public isHidden: boolean;
	public levels: ExerciseProgressionLevelViewModelRead[];
}
export class ExerciseProgressionViewModelRead extends ExerciseProgressionViewModel
{
	public id: number;
}
export class ExerciseTagViewModel
{
	public name: string;
}
export class ExerciseTagViewModelRead extends ExerciseTagViewModel
{
	public id: number;
}
export class ExerciseViewModel
{
	public name: string;
	public instructions: string;
	public videoLink: string;
	public videoType: VideoType;
	public organizationId?: number;
	public userId?: number;
	public workoutItemExerciseCount: number;
	public videoId?: number;
	public isHidden: boolean;
	public exerciseTags: ExerciseTagViewModel[];
	public exerciseEquipments: ExerciseEquipmentExerciseViewModel[];
}
export class ExerciseViewModelRead extends ExerciseViewModel
{
	public id: number;
	public userFullName: string;
	public inExerciseProgression: boolean;
	public relatedExerciseProgressionName: string;
}
export class ExerciseViewModelReadWithProgressionInfo extends ExerciseViewModelRead
{
	public exerciseLevelDownId?: number;
	public exerciseLevelUpId?: number;
}
export class WorkoutActivityTypeDurationViewModelRead
{
	public workoutId: number;
	public activityType: ActivityType;
	public expectedTime?: number;
	public expectedMiles?: number;
	public expectedKilometers?: number;
}
export class WorkoutDisplayParams
{
	public workoutId: number;
	public forCurrentUser: boolean;
	public clientProgramWorkoutDayId?: number;
}
export class WorkoutDisplayViewModelRead
{
	public id: number;
	public name: string;
	public description: string;
	public goal: string;
	public videoLink: string;
	public videoId?: number;
	public videoType: VideoType;
	public workoutType: WorkoutType;
	public coachNotes: string;
	public autoCompleteUrl: string;
	public clientProgramWorkoutDayId?: number;
	public workoutCategory: WorkoutCategory;
	public expectedTime?: number;
	public expectedRPE?: number;
	public garminPoolLength?: number;
	public garminPoolLengthUnit?: GarminPoolLengthUnit;
	public workoutKeywords: WorkoutKeywordViewModelRead[];
	public hasExercises: boolean;
	public workoutItems: WorkoutItemDisplayViewModelRead[];
}
export class WorkoutDisplayExtendedViewModelRead extends WorkoutDisplayViewModelRead
{
	public workoutInstances: WorkoutInstanceViewModelRead[];
}
export class WorkoutInstanceViewModelRead
{
	public workoutDate: Date;
	public coachNotes: string;
}
export class WorkoutItemCardioViewModel
{
	public sortOrder: number;
	public cardioType: CardioType;
	public durationDistance?: number;
	public distanceUOM: CardioDistanceUOM;
	public durationTime?: number;
	public intensityType?: CardioIntensityType;
	public intensityValue?: number;
	public intensityValue2?: number;
	public notes: string;
	public activityType: ActivityType;
	public vdotPaceType?: VDOTPaceType;
}
export class WorkoutItemCardioViewModelRead extends WorkoutItemCardioViewModel
{
	public id: number;
	public workoutItemId: number;
}
export class WorkoutItemDisplayDetailsViewModelRead
{
	public id: number;
	public workoutItemId: number;
	public sortOrder: number;
	public text: string;
	public details: string;
	public instructions: string;
	public videoLink: string;
	public notes: string;
	public cardioType?: CardioType;
	public videoType: VideoType;
	public videoId?: number;
	public vdotChartLink: string;
	public cardioIntensityType?: CardioIntensityType;
	public garminWorkoutStepTargetType?: GarminWorkoutStepTargetType;
	public defaultExerciseId: number;
	public exerciseLevelDownId?: number;
	public exerciseLevelUpId?: number;
	public inExerciseProgression: boolean;
	public exerciseProgress: ClientProgramWorkoutDayExerciseProgressViewModel;
}
export class WorkoutItemDisplayViewModelRead
{
	public id: number;
	public workoutId: number;
	public sortOrder: number;
	public workoutItemType: WorkoutItemType;
	public repeat?: number;
	public workoutItemGarminRepeatType?: WorkoutItemGarminRepeatType;
	public stepText: string;
	public text: string;
	public workoutItemDetails: WorkoutItemDisplayDetailsViewModelRead[];
	public isCompleted: boolean;
	public userCredentialId?: number;
}
export class WorkoutItemExerciseViewModel
{
	public exerciseId: number;
	public sortOrder: number;
	public rep?: number;
	public set?: number;
	public resistance: string;
	public notes: string;
	public durationForCardio?: number;
	public resistanceType?: ExerciseResistanceType;
	public weight?: number;
	public hold?: number;
	public weightUOM?: WeightUOM;
}
export class WorkoutItemExerciseViewModelRead extends WorkoutItemExerciseViewModel
{
	public id: number;
	public workoutItemId: number;
	public exercise: ExerciseViewModelRead;
}
export class WorkoutItemGarminStepViewModel
{
	public sortOrder: number;
	public intensity: GarminWorkoutStepIntensity;
	public description: string;
	public durationType: GarminWorkoutStepDurationType;
	public durationValue?: number;
	public durationValueType?: GarminWorkoutStepDurationValueType;
	public targetType?: GarminWorkoutStepTargetType;
	public targetValue?: number;
	public targetValueLow?: number;
	public targetValueHigh?: number;
	public targetValueType?: GarminWorkoutStepTargetValueType;
	public strokeType?: GarminWorkoutStepStrokeType;
	public equipmentType?: GarminWorkoutStepEquipmentType;
}
export class WorkoutItemGarminStepViewModelRead extends WorkoutItemGarminStepViewModel
{
	public id: number;
	public workoutItemId: number;
}
export class WorkoutItemViewModel
{
	public sortOrder: number;
	public workoutItemType: WorkoutItemType;
	public repeat?: number;
	public workoutItemGarminRepeatType?: WorkoutItemGarminRepeatType;
	public workoutItemExercises: WorkoutItemExerciseViewModelRead[];
	public workoutItemCardios: WorkoutItemCardioViewModelRead[];
	public workoutItemGarminSteps: WorkoutItemGarminStepViewModelRead[];
}
export class WorkoutItemViewModelRead extends WorkoutItemViewModel
{
	public id: number;
	public workoutId: number;
}
export class WorkoutKeywordViewModel
{
	public name: string;
}
export class WorkoutKeywordViewModelRead extends WorkoutKeywordViewModel
{
	public id: number;
}
export class WorkoutListViewModelRead
{
	public id: number;
	public name: string;
	public goal: string;
	public organizationId?: number;
	public userFullName: string;
	public expectedTime?: number;
	public expectedRPE?: number;
	public workoutType: WorkoutType;
	public programWorkoutDayCount: number;
	public clientProgramWorkoutDayCount: number;
	public workoutKeywords: WorkoutKeywordViewModelRead[];
	public videoLink: string;
	public videoId?: number;
	public description: string;
	public workoutCategory: WorkoutCategory;
	public inWorkoutProgression: boolean;
}
export class WorkoutProgressionViewModel
{
	public name: string;
	public organizationId?: number;
	public userId?: number;
	public notes: string;
	public isHidden: boolean;
	public levels: WorkoutProgressionLevelViewModelRead[];
}
export class WorkoutProgressionViewModelRead extends WorkoutProgressionViewModel
{
	public id: number;
}
export class WorkoutProgressionLevelViewModel
{
	public level: number;
	public workoutId: number;
}
export class WorkoutProgressionLevelViewModelRead extends WorkoutProgressionLevelViewModel
{
	public id: number;
	public workoutProgressionId: number;
	public workoutName: string;
}
export class WorkoutProgressionListViewModelRead
{
	public id: number;
	public name: string;
	public organizationId?: number;
	public userFullName: string;
	public notes: string;
}
export class WorkoutSummaryViewModelRead
{
	public id: number;
	public name: string;
	public goal: string;
	public organizationId?: number;
	public userFullName: string;
	public workoutType: WorkoutType;
	public expectedRPE?: number;
	public expectedTrainingLoad: number;
	public expectedTime?: number;
	public expectedMiles?: number;
	public expectedKilometers?: number;
	public singleActivityType?: ActivityType;
	public activityTypeDurations: WorkoutActivityTypeDurationViewModelRead[];
	public inWorkoutProgression: boolean;
}
export class WorkoutViewModel
{
	public name: string;
	public description: string;
	public organizationId?: number;
	public userId?: number;
	public goal: string;
	public workoutType: WorkoutType;
	public expectedTime?: number;
	public expectedMiles?: number;
	public expectedKilometers?: number;
	public programWorkoutDayCount: number;
	public clientProgramWorkoutDayCount: number;
	public isHidden: boolean;
	public videoType: VideoType;
	public videoLink: string;
	public videoId?: number;
	public workoutCategory: WorkoutCategory;
	public expectedRPE?: number;
	public expectedTrainingLoad: number;
	public singleActivityType?: ActivityType;
	public garminPoolLength?: number;
	public garminPoolLengthUnit?: GarminPoolLengthUnit;
	public workoutKeywords: WorkoutKeywordViewModel[];
	public workoutItems: WorkoutItemViewModelRead[];
	public activityTypeDurations: WorkoutActivityTypeDurationViewModelRead[];
}
export class WorkoutViewModelRead extends WorkoutViewModel
{
	public id: number;
	public userFullName: string;
	public inWorkoutProgression: boolean;
	public relatedWorkoutProgressionName: string;
}
export class UserCredentialGarminWorkoutViewModel
{
	public userCredentialId: number;
	public workoutId: number;
	public workoutModifiedDate: Date;
	public garminWorkoutId: number;
	public garminOwnerId: number;
}
export class UserCredentialGarminWorkoutViewModelRead extends UserCredentialGarminWorkoutViewModel
{
	public id: number;
	public modifiedDate: Date;
}
export class ClientSummaryDTO
{
	public userId: number;
	public organizationId: number;
	public firstName: string;
	public lastName: string;
	public userFullName: string;
	public nextScheduledEvent?: Date;
	public completionPercentage?: number;
	public userRole: UserRole;
	public status: ClientStatus;
	public email: string;
	public coachFullName: string;
	public dailyStatusPercentEffortMin: number;
	public dailyStatusPercentEffortMax: number;
	public dailyStatusPainDays: number;
	public lastScheduledWorkoutDate?: Date;
	public acuteChronicWorkloadRatioPercentage?: number;
	public athleteType: AthleteType;
}
export class UserSearchResultViewModel
{
	public id: number;
	public userCredentialId: number;
	public firstName: string;
	public lastName: string;
	public createdDate: Date;
	public fullName: string;
	public status: ClientStatus;
	public userRole: UserRole;
	public email: string;
	public organizationId: number;
	public organizationName: string;
	public organizationActiveOrTrialSubscription: boolean;
}
export class LoginViewModel
{
	public email: string;
	public password: string;
}
export class LoginWithParamsViewModel
{
	public email: string;
	public password: string;
	public preferredOrganizationId?: number;
	public preferredOrganizationAPI_Id?: number;
	public preferSelfCoachedOrg: boolean;
	public preferAthleteOrg: boolean;
}
export class OrganizationRegistrationViewModel
{
	public organizationName: string;
	public firstName: string;
	public promoCode: string;
	public lastName: string;
	public email: string;
	public password: string;
	public fromPlanBuilder: boolean;
	public brandId?: number;
	public termsAndConditionsVersion?: number;
}
export class StringViewModel
{
	public value: string;
}
export class GaitComparisonViewModel
{
	public firstReportGuidId: string;
	public secondReportGuidId: string;
}
export class ImageViewModel
{
	public blobPath: string;
	public base64File: string;
	public isPrimary: boolean;
}
export class RefreshTokenViewModel
{
	public token: string;
}
export class BooleanViewModel
{
	public value: boolean;
}
export class ReinstateOrgTrialViewModel
{
	public orgFound: boolean;
	public organizationId?: number;
}
export class SendMessageViewModel
{
	public to?: number;
	public toTeamId?: number;
	public subject: string;
	public body: string;
	public currentMessageMode: CurrentMessageMode;
	public clientProgramWorkoutDayId?: number;
}
export class MessageViewModel
{
	public id: number;
	public fromUserFirstName: string;
	public fromUserLastName: string;
	public fromUserId: number;
	public fromUserIsActiveAthlete: boolean;
	public toUserFirstName: string;
	public toUserLastName: string;
	public toUserId: number;
	public toUserIsActiveAthlete: boolean;
	public toTeamId?: number;
	public toTeamName: string;
	public read: boolean;
	public subject: string;
	public body: string;
	public createdDate: string;
	public clientProgramWorkoutDayId?: number;
	public batchKey: string;
}
export class ProgramPurchaseHistoryViewModel
{
	public createdDate: Date;
	public programId: number;
	public programName: string;
	public programStartDate: Date;
	public buyerUserId: number;
	public buyerUserFullName: string;
	public sellerUserId?: number;
	public sellerUserFullName: string;
	public sellerOrganizationId?: number;
	public price: number;
	public netPrice: number;
	public stripePaymentInfo: string;
	public promoCodeId?: number;
	public stripeTransferId: string;
}
export class ProgramPurchaseHistoryViewModelRead extends ProgramPurchaseHistoryViewModel
{
	public id: number;
	public promoCodeCode: string;
}
export class AssessmentProgramAssignmentViewModel
{
	public assessmentId: number;
	public impairmentCategoryProgramId?: number;
	public impairmentCategoryProgramStartDate?: Date;
	public impairmentCategoryRTRNotes: string;
	public movementCategoryProgramId?: number;
	public movementCategoryProgramStartDate?: Date;
	public optionalProgramId?: number;
	public optionalProgramStartDate?: Date;
	public clientUserId: number;
}
export class ClientProgramListViewModel
{
	public createdDate: Date;
	public id: number;
	public name: string;
	public videoLink: string;
	public description: string;
	public weekCount: number;
	public startDate: Date;
	public expectedWorkoutProgressPercentage: number;
	public actualWorkoutProgressPercentage: number;
	public videoType: VideoType;
	public videoId?: number;
	public isPaidProgram: boolean;
	public refProgramId?: number;
	public deactivated: boolean;
}
export class ClientProgramViewModel
{
	public name: string;
	public videoLink: string;
	public description: string;
	public weekCount: number;
	public startDate: Date;
	public isPaidProgram: boolean;
	public clientWorkoutDays: ClientProgramWorkoutDayViewModelRead[];
}
export class ClientProgramViewModelRead extends ClientProgramViewModel
{
	public id: number;
}
export class ClientProgramWorkoutCombinedViewModel
{
	public organizationId: number;
	public clientUserId: number;
	public assignedByUserId: number;
	public workoutName: string;
	public workoutDate: Date;
	public isCompleted: boolean;
	public workoutId?: number;
	public garminActivityId?: number;
	public coachNotes: string;
	public ratePerceivedExertion?: number;
	public clientProgramWorkoutDayItems: ClientProgramWorkoutDayItemViewModelRead[];
	public activityTypeDurations: ClientProgramWorkoutDayActivityTypeDurationViewModel[];
	public stravaActivityId?: number;
	public eventType: ClientDayEventType;
	public workout: WorkoutViewModelRead;
	public messages: MessageViewModel[];
	public clientProgramWorkoutDayNotes: ClientProgramWorkoutDayNoteViewModelRead[];
	public workoutJournals: ClientProgramWorkoutJournalViewModel[];
	public workoutLevelDownId?: number;
	public workoutLevelUpId?: number;
	public workoutDisplay: WorkoutDisplayViewModelRead;
	public externalWorkoutSummary: ExternalWorkoutSummaryViewModelRead;
	public externalElevationChart: ExternalWorkoutChartData[];
	public externalPaceChart: ExternalWorkoutPaceChartData[];
	public externalHeartRateChart: ExternalWorkoutChartData[];
	public externalCadenceChart: ExternalWorkoutChartData[];
	public externalStrideLengthChart: ExternalWorkoutChartData[];
	public externalLapChart: ExternalWorkoutLapChartData[];
	public externalSpeedChart: ExternalWorkoutChartData[];
	public isRPERequired: boolean;
	public isExternalWorkout: boolean;
	public isManualWorkout: boolean;
	public hasManualWorkoutDistance: boolean;
	public isMatchedWorkout: boolean;
	public hasCoachNotes: boolean;
	public hasWorkoutId: boolean;
	public hasRPESelected: boolean;
	public isScheduledWorkoutOnly: boolean;
	public isCompletedWorkoutAndNotExternalWorkout: boolean;
	public isCompletedWithRPE: boolean;
	public isIncompleteWorkoutId: boolean;
	public isInWorkoutProgression: boolean;
}
export class ClientProgramWorkoutDayActivityTypeDurationViewModel
{
	public clientProgramWorkoutDayId: number;
	public activityType: ActivityType;
	public duration: number;
	public distance?: number;
}
export class ClientProgramWorkoutDayEditViewModel
{
	public id: number;
	public coachNotes: string;
	public sortOrder: number;
	public workoutDate: Date;
}
export class ClientProgramWorkoutDayExerciseProgressSetViewModel
{
	public setNumber: number;
	public reps?: number;
	public weight?: number;
	public hold?: number;
	public resistance: string;
	public isCompleted: boolean;
}
export class ClientProgramWorkoutDayExerciseProgressSetViewModelRead extends ClientProgramWorkoutDayExerciseProgressSetViewModel
{
	public id: number;
	public clientProgramWorkoutDayExerciseProgressId: number;
}
export class ClientProgramWorkoutDayExerciseProgressViewModel
{
	public clientProgramWorkoutDayId: number;
	public sortOrder: number;
	public exerciseId: number;
	public exerciseName: string;
	public resistanceType: ExerciseResistanceType;
	public weightUOM?: WeightUOM;
	public expectedSets: number;
	public exerciseProgressSets: ClientProgramWorkoutDayExerciseProgressSetViewModelRead[];
}
export class ClientProgramWorkoutDayExerciseProgressViewModelRead extends ClientProgramWorkoutDayExerciseProgressViewModel
{
	public id: number;
}
export class ClientProgramWorkoutDayItemViewModel
{
	public clientProgramWorkoutDayId: number;
	public workoutItemId: number;
	public isCompleted: boolean;
	public completedDate?: Date;
}
export class ClientProgramWorkoutDayItemViewModelRead extends ClientProgramWorkoutDayItemViewModel
{
	public id: number;
}
export class ClientProgramWorkoutDayListViewModelRead
{
	public id: number;
	public workoutName: string;
	public workoutType?: WorkoutType;
	public autoCompleteUrl: string;
}
export class ClientProgramWorkoutDayNoteViewModel
{
	public clientProgramWorkoutDayId: number;
	public fromUserFirstName: string;
	public fromUserLastName: string;
	public fromUserId: number;
	public note: string;
	public createdDate: string;
}
export class ClientProgramWorkoutDayNoteViewModelRead extends ClientProgramWorkoutDayNoteViewModel
{
	public id: number;
}
export class ClientProgramWorkoutDayViewModel
{
	public organizationId: number;
	public clientUserId: number;
	public assignedByUserId?: number;
	public workoutId?: number;
	public workoutDate: Date;
	public sortOrder: number;
	public isCompleted: boolean;
	public completedDate?: Date;
	public eventName: string;
	public garminActivityId?: number;
	public coachNotes: string;
	public eventType: ClientDayEventType;
	public taskDescription: string;
	public ratePerceivedExertion?: number;
	public recurringEvent: RecurringEventViewModelRead;
	public activityTypeDurations: ClientProgramWorkoutDayActivityTypeDurationViewModel[];
	public stravaActivityId?: number;
	public quickWorkoutName: string;
	public quickWorkoutDescription: string;
	public quickWorkoutActivityType?: ActivityType;
	public quickWorkoutDuration?: number;
	public quickWorkoutDistance?: number;
}
export class ClientProgramWorkoutDayViewModelRead extends ClientProgramWorkoutDayViewModel
{
	public id: number;
	public clientProgramId?: number;
	public workoutName: string;
	public workoutType?: WorkoutType;
}
export class ClientProgramWorkoutJournalViewModel
{
	public id: number;
	public clientProgramWorkoutDayId?: number;
	public fromUserFirstName: string;
	public fromUserLastName: string;
	public fromUserId: number;
	public body: string;
	public createdDate: string;
}
export class ClientProgramWorkoutViewModel
{
	public id: number;
	public organizationId: number;
	public clientUserId: number;
	public assignedByUserId: number;
	public workoutName: string;
	public workoutDate: Date;
	public isCompleted: boolean;
	public workoutId?: number;
	public garminActivityId?: number;
	public coachNotes: string;
	public ratePerceivedExertion?: number;
	public clientProgramWorkoutDayItems: ClientProgramWorkoutDayItemViewModelRead[];
	public activityTypeDurations: ClientProgramWorkoutDayActivityTypeDurationViewModel[];
	public stravaActivityId?: number;
	public eventType: ClientDayEventType;
	public syncedWorkoutName: string;
	public workout: WorkoutViewModelRead;
	public messages: MessageViewModel[];
	public clientProgramWorkoutDayNotes: ClientProgramWorkoutDayNoteViewModelRead[];
	public workoutJournals: ClientProgramWorkoutJournalViewModel[];
	public workoutLevelDownId?: number;
	public workoutLevelUpId?: number;
	public quickWorkoutName: string;
	public quickWorkoutDescription: string;
	public quickWorkoutActivityType?: ActivityType;
	public quickWorkoutDuration?: number;
	public quickWorkoutDistance?: number;
	public garminWorkoutScheduleId?: number;
	public clientUserHasGarminTrainingPermission: boolean;
	public exerciseProgresses: ClientProgramWorkoutDayExerciseProgressViewModelRead[];
}
export class GaitCapabilityAssessmentProgramAssignmentViewModel
{
	public assessmentId: number;
	public gaitCapabilityCategoryProgramId?: number;
	public gaitCapabilityCategory: GaitCapabilityCategory;
	public gaitCapabilityCategoryProgramStartDate?: Date;
	public optionalProgramId?: number;
	public optionalProgramStartDate?: Date;
	public clientUserId: number;
}
export class GaitCapabilityCategoryProgramViewModel
{
	public gaitCapabilityCategory: GaitCapabilityCategory;
	public programId?: number;
}
export class GaitCapabilityCategoryProgramViewModelRead extends GaitCapabilityCategoryProgramViewModel
{
	public program: ProgramListViewModelRead;
}
export class GaitCapabilityCategoryUpdateViewModel
{
	public gaitCapabilityCategory: GaitCapabilityCategory;
	public gaitCapabilityCategoryProgramId: number;
	public gaitCapabilityCategoryProgramStartDate: Date;
	public clientUserId: number;
	public rtrNotes: string;
}
export class ImpairmentCategoryProgramViewModel
{
	public impairmentCategory: ImpairmentCategory;
	public programId?: number;
}
export class ImpairmentCategoryProgramViewModelRead extends ImpairmentCategoryProgramViewModel
{
	public program: ProgramListViewModelRead;
}
export class ImpairmentCategoryUpdateViewModel
{
	public impairmentCategory: ImpairmentCategory;
	public impairmentCategoryProgramId: number;
	public impairmentCategoryProgramStartDate: Date;
	public clientUserId: number;
	public rtrNotes: string;
}
export class LoadingLevelsProgramAssignmentViewModel
{
	public loadingLevelsAssessmentId: number;
	public loadingLevelProgramId?: number;
	public loadingLevelProgramStartDate?: Date;
	public optionalProgramId?: number;
	public optionalProgramStartDate?: Date;
	public clientUserId: number;
}
export class LoadingLevelsProgramViewModel
{
	public loadingLevel: number;
	public programId?: number;
}
export class LoadingLevelsProgramViewModelRead extends LoadingLevelsProgramViewModel
{
	public program: ProgramListViewModelRead;
}
export class MovementCategoryProgramViewModel
{
	public movementCategory: MovementCategory;
	public programId?: number;
}
export class MovementCategoryProgramViewModelRead extends MovementCategoryProgramViewModel
{
	public program: ProgramListViewModelRead;
}
export class ProgramAssignmentViewModel
{
	public programId: number;
	public clientUserId: number;
	public assignedByUserId?: number;
	public startDate: Date;
	public isPurchasedProgram: boolean;
}
export class ProgramTeamAssignmentViewModel
{
	public programId: number;
	public teamId: number;
	public assignedByUserId: number;
	public startDate: Date;
}
export class SurveyProgramAssignmentViewModel
{
	public programId: number;
	public startDate: Date;
}
export class ProgramKeywordViewModel
{
	public name: string;
	public organizationId: number;
}
export class ProgramKeywordViewModelRead extends ProgramKeywordViewModel
{
	public id: number;
}
export class ProgramListViewModelRead
{
	public id: number;
	public name: string;
	public organizationId?: number;
	public userFullName: string;
	public weekCount: number;
	public isPaidProgram: boolean;
	public isArchived: boolean;
	public canBeDeleted: boolean;
	public canBeArchived: boolean;
	public price?: number;
	public description: string;
	public programKeywords: ProgramKeywordViewModelRead[];
}
export class GlobalMarketplaceProgramListViewModelRead extends ProgramListViewModelRead
{
	public organizationFeaturedInGlobalMarketplace: boolean;
	public organizationMarketplaceURLSlug: string;
	public globalMarketplaceProgramType: GlobalMarketplaceProgramType;
	public globalMarketplaceExperienceLevel: GlobalMarketplaceExperienceLevel;
}
export class ProgramAllowedActionViewModel
{
	public canBeDeleted: boolean;
	public canBeArchived: boolean;
}
export class ProgramStatusViewModelRead
{
	public name: string;
	public value: boolean;
}
export class ProgramSummaryViewModelRead
{
	public id: number;
	public name: string;
	public organizationId?: number;
	public userFullName: string;
	public userBiography: string;
	public userId: number;
	public videoLink: string;
	public videoType: VideoType;
	public description: string;
	public weekCount: number;
	public videoId?: number;
	public isPaidProgram: boolean;
	public price?: number;
	public programCategory?: ProgramCategory;
	public showInGlobalMarketplace: boolean;
	public globalMarketplaceProgramType?: GlobalMarketplaceProgramType;
	public globalMarketplaceExperienceLevel?: GlobalMarketplaceExperienceLevel;
	public programFirstWeek: ProgramWeekViewModelRead[];
	public programWorkoutDaysFirstWeek: ProgramWorkoutDayViewModelRead[];
}
export class ProgramSummaryViewModelReadExtended extends ProgramSummaryViewModelRead
{
	public purchaseDate: Date;
	public isActive: boolean;
	public paidProgramUrl: string;
}
export class ProgramViewModel
{
	public name: string;
	public organizationId?: number;
	public userId?: number;
	public videoLink: string;
	public videoType: VideoType;
	public description: string;
	public weekCount: number;
	public videoId?: number;
	public isPaidProgram: boolean;
	public price?: number;
	public isHidden: boolean;
	public isArchived: boolean;
	public programCategory?: ProgramCategory;
	public createdFromRunDNAClone: boolean;
	public showInGlobalMarketplace: boolean;
	public globalMarketplaceProgramType?: GlobalMarketplaceProgramType;
	public globalMarketplaceExperienceLevel?: GlobalMarketplaceExperienceLevel;
	public programKeywords: ProgramKeywordViewModel[];
	public workoutDays: ProgramWorkoutDayViewModelRead[];
	public programWeeks: ProgramWeekViewModelRead[];
}
export class ProgramViewModelRead extends ProgramViewModel
{
	public id: number;
	public userFullName: string;
}
export class ProgramWeekViewModel
{
	public weekNumber: number;
	public trainingLoadPeriodType?: TrainingLoadPeriodType;
}
export class ProgramWeekViewModelRead extends ProgramWeekViewModel
{
	public id: number;
	public programId: number;
}
export class ProgramWorkoutDayViewModel
{
	public workoutId?: number;
	public dayNumber: number;
	public sortOrder: number;
	public coachNotes: string;
	public eventName: string;
	public eventType: ClientDayEventType;
	public taskDescription: string;
	public quickWorkoutName: string;
	public quickWorkoutDescription: string;
	public quickWorkoutActivityType?: ActivityType;
	public quickWorkoutDuration?: number;
	public quickWorkoutDistance?: number;
}
export class ProgramWorkoutDayViewModelRead extends ProgramWorkoutDayViewModel
{
	public id: number;
	public programId: number;
	public workout: WorkoutSummaryViewModelRead;
	public workoutName: string;
	public quickWorkoutExpectedTrainingLoad: number;
}
export class PurchasePaidProgramViewModel
{
	public programId: number;
	public price: number;
	public programStartDate: Date;
	public stripeToken: string;
	public promoCodeId?: number;
}
export class TeamProgramViewModel
{
	public name: string;
	public refProgramId: number;
	public videoType: VideoType;
	public videoId?: number;
	public videoLink: string;
	public description: string;
	public weekCount: number;
	public startDate: Date;
	public teamWorkoutDays: TeamProgramWorkoutDayViewModelRead[];
}
export class TeamProgramViewModelRead extends TeamProgramViewModel
{
	public id: number;
}
export class TwoDimensionalProgramAssignmentViewModel
{
	public twoDimensionalAssessmentId: number;
	public impairmentCategoryProgramId?: number;
	public impairmentCategoryProgramStartDate?: Date;
	public optionalProgramId?: number;
	public optionalProgramStartDate?: Date;
	public clientUserId: number;
}
export class WalkingCategoryProgramViewModel
{
	public walkingCategory: WalkingCategory;
	public programId?: number;
}
export class WalkingCategoryProgramViewModelRead extends WalkingCategoryProgramViewModel
{
	public program: ProgramListViewModelRead;
}
export class WalkingCategoryUpdateViewModel
{
	public walkingCategory: WalkingCategory;
	public walkingCategoryProgramId: number;
	public walkingCategoryProgramStartDate: Date;
	public clientUserId: number;
	public rtrNotes: string;
}
export class WalkingProgramAssignmentViewModel
{
	public assessmentId: number;
	public walkingCategoryProgramId?: number;
	public walkingCategory: WalkingCategory;
	public walkingCategoryProgramStartDate?: Date;
	public optionalProgramId?: number;
	public optionalProgramStartDate?: Date;
	public clientUserId: number;
}
export class PromoCodeViewModel
{
	public code: string;
	public startDate: Date;
	public endDate: Date;
	public percentageDiscount: number;
	public programKeywords: ProgramKeywordViewModelRead[];
}
export class PromoCodeViewModelRead extends PromoCodeViewModel
{
	public id: number;
	public organizationId: number;
}
export class QuickWorkoutDisplayViewModelRead
{
	public clientProgramWorkoutDayId?: number;
	public workoutDate: Date;
	public quickWorkoutName: string;
	public hasDescription: boolean;
	public text: string;
	public autoCompleteUrl: string;
	public quickWorkoutDescription: string;
	public quickWorkoutActivityType?: ActivityType;
	public quickWorkoutDuration?: number;
	public quickWorkoutDistance?: number;
}
export class RacePlanListViewModel
{
	public userId: number;
	public coachUserId?: number;
	public name: string;
	public raceDistanceType: RaceDistanceType;
	public customDistance?: number;
	public defaultPaceInSeconds?: number;
	public notes: string;
	public raceDate: Date;
	public finishTime: number;
}
export class RacePlanListViewModelRead extends RacePlanListViewModel
{
	public id: number;
}
export class RacePlanSplitViewModel
{
	public racePlanId: number;
	public splitNumber: number;
	public splitDistance: number;
	public splitPaceInSeconds: number;
	public splitDurationInSeconds: number;
	public cumulativeDurationInSeconds: number;
	public notes: string;
}
export class RacePlanSplitViewModelRead extends RacePlanSplitViewModel
{
	public id: number;
}
export class RacePlanViewModel
{
	public userId: number;
	public coachUserId?: number;
	public name: string;
	public raceDistanceType: RaceDistanceType;
	public customDistance?: number;
	public defaultPaceInSeconds?: number;
	public notes: string;
	public raceDate: Date;
	public finishTime: number;
	public racePlanSplits: RacePlanSplitViewModelRead[];
}
export class RacePlanViewModelRead extends RacePlanViewModel
{
	public id: number;
}
export class RegistrationViewModel
{
	public email: string;
	public password: string;
	public firstName: string;
	public lastName: string;
	public userRole: UserRole;
}
export class ResetPasswordViewModel
{
	public userCredentialId: number;
	public resetToken: string;
	public newPassword: string;
}
export class ClientSearchParams
{
	public searchText: string;
	public status?: ClientStatus;
	public teamId?: number;
	public page: number;
	public size: number;
	public sortProperty: string;
	public isSortDesc: boolean;
	public athleteType?: AthleteType;
}
export class UserSearchParams
{
	public searchText: string;
	public page: number;
	public size: number;
}
export class ProgramSearchParams
{
	public searchText: string;
	public page: number;
	public size: number;
	public sortProperty: string;
	public isSortDesc: boolean;
	public programKeywordId?: number;
	public coachId?: number;
	public showArchived: boolean;
}
export class ExerciseSearchParams
{
	public searchText: string;
	public page: number;
	public size: number;
	public sortProperty: string;
	public isSortDesc: boolean;
	public coachId?: number;
}
export class WorkoutSearchParams
{
	public searchText: string;
	public page: number;
	public size: number;
	public sortProperty: string;
	public isSortDesc: boolean;
	public coachId?: number;
}
export class WorkoutProgressionSearchParams
{
	public searchText: string;
	public page: number;
	public size: number;
	public sortProperty: string;
	public isSortDesc: boolean;
	public coachId?: number;
}
export class ExerciseProgressionSearchParams
{
	public searchText: string;
	public page: number;
	public size: number;
	public sortProperty: string;
	public isSortDesc: boolean;
	public coachId?: number;
}
export class OrganizationSearchParams
{
	public searchText: string;
	public isExpired: boolean;
}
export class GlobalPaidProgramsSearchParams
{
	public searchText: string;
	public page: number;
	public size: number;
	public sortProperty: string;
	public isSortDesc: boolean;
	public experienceLevel?: GlobalMarketplaceExperienceLevel;
	public programType?: GlobalMarketplaceProgramType;
}
export class StripeConnectedAccountBalanceViewModel
{
	public available: number;
	public pending: number;
	public weeklySum: number;
	public weeklyCount: number;
}
export class SuperuserStatsViewModel
{
	public trialsStartedInLast30Days: number;
	public paidSubscriptionsStartedInLast30Days: number;
	public totalActiveAthletes: number;
	public totalActiveOrgs: number;
	public totalActiveAthletesInMultipleOrgs: number;
	public totalActiveCoachesInMultipleOrgs: number;
	public totalPaidSubscriptions: number;
	public totalMonthlySubscriptionPrice: number;
	public organizationsWithSubscription: OrganizationStripeInfoViewModel[];
}
export class OrganizationStripeInfoViewModel
{
	public id: number;
	public name: string;
	public activationDate: Date;
	public runnerSlots: number;
	public subscriptionPrice: number;
	public isMonthly: boolean;
}
export class TeamDetailsViewModelRead
{
	public id: number;
	public name: string;
	public coaches: UserSummaryViewModel[];
	public athletes: UserSummaryViewModel[];
}
export class TeamAddViewModel
{
	public name: string;
	public coachIds: number[];
	public athleteIds: number[];
}
export class TeamEditViewModel
{
	public id: number;
	public name: string;
	public coachIds: number[];
	public athleteIds: number[];
}
export class TeamUserEditViewModel
{
	public coachIds: number[];
	public athleteIds: number[];
}
export class TeamListViewModel
{
	public name: string;
}
export class TeamListViewModelRead extends TeamListViewModel
{
	public id: number;
}
export class TeamProgramListViewModel
{
	public id: number;
	public name: string;
	public videoLink: string;
	public description: string;
	public weekCount: number;
	public startDate: Date;
	public expectedWorkoutProgressPercentage: number;
	public videoType: VideoType;
	public videoId?: number;
}
export class TeamProgramWorkoutDayEditViewModel
{
	public id: number;
	public coachNotes: string;
	public sortOrder: number;
	public workoutDate: Date;
}
export class TeamProgramWorkoutDayViewModel
{
	public organizationId: number;
	public teamId: number;
	public assignedByUserId?: number;
	public workoutId?: number;
	public workoutDate: Date;
	public sortOrder: number;
	public eventName: string;
	public coachNotes: string;
	public eventType: ClientDayEventType;
	public taskDescription: string;
	public teamProgramWorkoutRecurringEventId?: number;
	public recurringEvent: RecurringEventViewModelRead;
	public quickWorkoutName: string;
	public quickWorkoutDescription: string;
	public quickWorkoutActivityType?: ActivityType;
	public quickWorkoutDuration?: number;
	public quickWorkoutDistance?: number;
}
export class TeamProgramWorkoutDayViewModelRead extends TeamProgramWorkoutDayViewModel
{
	public id: number;
	public teamProgramId?: number;
	public workoutName: string;
	public workoutType?: WorkoutType;
}
export class TeamSummaryViewModelRead
{
	public id: number;
	public name: string;
	public nextScheduledEvent?: Date;
	public lastScheduledWorkoutDate?: Date;
	public activeAthleteCount: string;
	public coaches: UserSummaryViewModel[];
}
export class TeamUserViewModel
{
	public teamId: number;
	public userId: number;
	public teamUserRole: TeamUserRole;
}
export class TeamUserViewModelRead extends TeamUserViewModel
{
	public id: number;
	public team: TeamListViewModelRead;
}
export class TeamViewModel
{
	public name: string;
}
export class TeamViewModelRead extends TeamViewModel
{
	public id: number;
}
export class TermsAndConditionsViewModelRead
{
	public id: number;
	public termsText: string;
	public version: number;
}
export class TrainingLoadPeriodViewModelRead
{
	public id: number;
	public trainingLoadPeriodType: TrainingLoadPeriodType;
	public trainingLoadMultiplier: number;
	public rpE1To4Multiplier: number;
	public rpE5To6Multiplier: number;
	public rpE7To8Multiplier: number;
	public rpE9To10Multiplier: number;
}
export class TrainingLoadPlannerListViewModelRead
{
	public id: number;
	public userId: number;
	public coachUserId?: number;
	public name: string;
	public startDate: Date;
	public startTrainingLoad: number;
	public weeksCount: number;
	public startNotes: string;
}
export class TrainingLoadPlannerViewModel
{
	public userId: number;
	public coachUserId?: number;
	public name: string;
	public startDate: Date;
	public startTrainingLoad: number;
	public startNotes: string;
	public trainingLoadPlannerWeeks: TrainingLoadPlannerWeekViewModelRead[];
}
export class TrainingLoadPlannerViewModelRead extends TrainingLoadPlannerViewModel
{
	public id: number;
}
export class TrainingLoadPlannerWeekViewModel
{
	public trainingLoadPlannerId: number;
	public sortOrder: number;
	public startDate: Date;
	public trainingLoadPeriodType: TrainingLoadPeriodType;
	public suggestedTrainingLoad: number;
	public trainingLoadLow: number;
	public trainingLoadHigh: number;
	public rpE1To4Load: number;
	public rpE5To6Load: number;
	public rpE7To8Load: number;
	public rpE9To10Load: number;
	public notes: string;
}
export class TrainingLoadPlannerWeekViewModelRead extends TrainingLoadPlannerWeekViewModel
{
	public id: number;
}
export class UserAuthenticationViewModel
{
	public id: number;
	public userCredentialId: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public token: string;
	public userRole: UserRole;
	public preferredOrgFound: boolean;
	public hasMultipleValidUsers: boolean;
	public hasActiveCoachAthleteOrg: boolean;
	public status: ClientStatus;
	public termsAndConditionsVersion?: number;
	public measurementSystem: MeasurementSystem;
}
export class UserLookupViewModel
{
	public found: boolean;
	public foundInOrg: boolean;
	public userProfile: UserProfileViewModel;
}
export class UserLookupInputViewModel
{
	public email: string;
	public specifiedOrganizationId?: number;
}
export class UserCredentialCombinedJournalSearchParams
{
	public startDate: Date;
	public endDate: Date;
	public searchText: string;
	public journalType?: JournalType;
}
export class UserCredentialCombinedJournalViewModel
{
	public userCredentialId: number;
	public isFromUserCredentialId: boolean;
	public noteDate: Date;
	public note: string;
	public journalType: JournalType;
	public fromUserCredentialId?: number;
	public fromUserFirstName: string;
	public fromUserLastName: string;
	public workoutName: string;
	public workoutDate?: Date;
	public clientProgramWorkoutDayId?: number;
	public dailyStatusAbleToRun?: boolean;
	public dailyStatusHasPain?: boolean;
	public dailyStatusIsSick?: boolean;
	public dailyStatusPercentEffort?: number;
}
export class UserCredentialJournalEntryViewModel
{
	public userCredentialId: number;
	public fromUserCredentialId: number;
	public note: string;
}
export class UserCredentialJournalEntryViewModelRead extends UserCredentialJournalEntryViewModel
{
	public id: number;
}
export class UserCredentialStatIntensityByActivityTypeViewModel
{
	public userCredentialId: number;
	public intensity7DaysBiking: number;
	public intensity7DaysOther: number;
	public intensity7DaysRunning: number;
	public intensity7DaysStrength: number;
	public intensity7DaysSwimming: number;
	public intensity7DaysWalking: number;
	public intensityTrailing7to34DaysBiking: number;
	public intensityTrailing7to34DaysOther: number;
	public intensityTrailing7to34DaysRunning: number;
	public intensityTrailing7to34DaysStrength: number;
	public intensityTrailing7to34DaysSwimming: number;
	public intensityTrailing7to34DaysWalking: number;
}
export class UserCredentialStatIntensityByActivityTypeViewModelRead extends UserCredentialStatIntensityByActivityTypeViewModel
{
	public acwrBiking?: number;
	public acwrOther?: number;
	public acwrRunning?: number;
	public acwrStrength?: number;
	public acwrSwimming?: number;
	public acwrWalking?: number;
	public intensityInSeconds7Days: number;
	public intensityInSecondsTrailing7to34Days: number;
	public acuteChronicWorkloadRatioPercentage?: number;
}
export class UserCredentialWorkoutExerciseViewModel
{
	public userCredentialId: number;
	public workoutId: number;
	public workoutDefaultExerciseId: number;
	public userSelectedLevelExerciseId: number;
}
export class UserCredentialWorkoutExerciseViewModelRead extends UserCredentialWorkoutExerciseViewModel
{
	public id: number;
	public modifiedDate: Date;
	public workoutDefaultExercise: ExerciseViewModelRead;
	public userSelectedLevelExercise: ExerciseViewModelRead;
}
export class AddRunnerViewModel
{
	public teamId: number;
	public email: string;
	public firstName: string;
	public lastName: string;
	public gender?: Gender;
	public yearOfBirth?: number;
	public weightInPounds?: number;
	public heightInInches?: number;
	public yearsOfExperience?: RunningExperience;
	public highestLevelOfRunning?: LevelOfRunning;
	public coachUserId?: number;
	public teams: TeamUserViewModel[];
}
export class AddSTTRunnerViewModel
{
	public email: string;
	public firstName: string;
	public lastName: string;
	public coachUserId?: number;
	public userRole: UserRole;
}
export class AddPaidProgramRunnerViewModel
{
	public organizationId: number;
	public password: string;
	public email: string;
	public firstName: string;
	public lastName: string;
	public gender?: Gender;
	public yearOfBirth?: number;
	public weightInPounds?: number;
	public heightInInches?: number;
	public yearsOfExperience?: RunningExperience;
	public highestLevelOfRunning?: LevelOfRunning;
	public coachUserId?: number;
	public termsAndConditionsVersion?: number;
	public measurementSystem: MeasurementSystem;
}
export class UserSummaryViewModel
{
	public id: number;
	public organizationId: number;
	public userCredentialId: number;
	public userRole: UserRole;
	public firstName: string;
	public lastName: string;
	public fullName: string;
	public email: string;
	public status: ClientStatus;
	public coachFullName: string;
}
export class UserBasicViewModel
{
	public id: number;
	public organizationId: number;
	public organizationName: string;
	public organizationActiveOrTrialSubscription: boolean;
	public userRole: UserRole;
	public status: ClientStatus;
}
export class UserCoachViewModel
{
	public id: number;
	public organizationId: number;
	public organizationName: string;
	public coachFullName: string;
	public coachEmail: string;
}
export class UserAuthProfileViewModel
{
	public id: number;
	public userCredentialId: number;
	public organizationId: number;
	public userRole: UserRole;
	public firstName: string;
	public lastName: string;
	public fullName: string;
	public email: string;
	public emailVerified: boolean;
	public coachUserId?: number;
	public trialDaysLeft: number;
	public activeSubscription: boolean;
	public paidSubscription: boolean;
	public complimentarySubscriptionEndDate?: Date;
	public status: ClientStatus;
	public organizationType: OrganizationType;
	public organizationAPI_Id?: number;
	public organizationBrandKeyName: string;
	public organizationAvatar: string;
	public organizationMarketplaceStatus: MarketplaceStatus;
	public athleteType: AthleteType;
	public validUsersForUserCredential: UserBasicViewModel[];
	public hasActiveCoachAthleteOrg: boolean;
}
export class UserProfileViewModel
{
	public id: number;
	public userCredentialId: number;
	public organizationId: number;
	public userRole: UserRole;
	public firstName: string;
	public lastName: string;
	public fullName: string;
	public email: string;
	public phoneNumber: string;
	public avatar: string;
	public emailVerified: boolean;
	public garminLinked: boolean;
	public garminTrainingPermission: boolean;
	public stravaLinked: boolean;
	public yearOfBirth?: number;
	public gender?: Gender;
	public weightInPounds?: number;
	public heightInInches?: number;
	public yearsOfExperience?: RunningExperience;
	public highestLevelOfRunning?: LevelOfRunning;
	public coachUserId?: number;
	public trialDaysLeft: number;
	public activeSubscription: boolean;
	public status: ClientStatus;
	public teams: TeamUserViewModelRead[];
	public organizationType: OrganizationType;
	public organizationAPI_Id?: number;
	public hasPassword: boolean;
	public excludeGPSData: boolean;
	public biography: string;
	public athleteType: AthleteType;
	public maxHeartRate?: number;
	public cyclingFunctionalThresholdPower?: number;
	public measurementSystem: MeasurementSystem;
}
export class UpdateProfileViewModel
{
	public id: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public phoneNumber: string;
	public avatar: string;
	public base64Image: string;
	public yearOfBirth?: number;
	public gender?: Gender;
	public weightInPounds?: number;
	public heightInInches?: number;
	public yearsOfExperience?: RunningExperience;
	public highestLevelOfRunning?: LevelOfRunning;
	public coachUserId?: number;
	public biography: string;
	public athleteType: AthleteType;
	public maxHeartRate?: number;
	public cyclingFunctionalThresholdPower?: number;
	public measurementSystem: MeasurementSystem;
	public teams: TeamUserViewModel[];
}
export class UpdateProfileWithPasswordViewModel extends UpdateProfileViewModel
{
	public password: string;
	public termsAndConditionsVersion?: number;
}
export class UserViewModel
{
	public id: number;
	public userCredentialId: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public phoneNumber: string;
	public avatar: string;
	public superUser: boolean;
	public organizationId: number;
	public userRole: UserRole;
}
export class ChangePasswordViewModel
{
	public currentPassword: string;
	public newPassword: string;
}
export class VideoViewModel
{
	public fileName: string;
	public fileNameDisplay: string;
}
export class VideoViewModelRead extends VideoViewModel
{
	public id: number;
	public assetPath: string;
	public streamingLocatorName: string;
	public encoded: boolean;
}
export class SurveyQuestionAnswerViewModel
{
	public sortOrder: number;
	public answer: string;
	public surveyWorkflows: SurveyWorkflowViewModel[];
}
export class SurveyQuestionAnswerViewModelRead extends SurveyQuestionAnswerViewModel
{
	public id: number;
	public surveyQuestionId: number;
	public surveyQuestion: SurveyQuestionSummaryViewModelRead;
}
export class SurveyQuestionSummaryViewModel
{
	public question: string;
	public description: string;
	public surveyQuestionType: SurveyQuestionType;
	public videoType: VideoType;
	public videoId?: number;
	public videoLink: string;
}
export class SurveyQuestionSummaryViewModelRead extends SurveyQuestionSummaryViewModel
{
	public id: number;
	public surveyId: number;
}
export class SurveyQuestionViewModel
{
	public question: string;
	public description: string;
	public surveyQuestionType: SurveyQuestionType;
	public videoType: VideoType;
	public videoId?: number;
	public videoLink: string;
	public nextSurveyQuestionId?: number;
	public progressPercentage: number;
	public surveyQuestionAnswers: SurveyQuestionAnswerViewModel[];
}
export class SurveyQuestionViewModelRead extends SurveyQuestionViewModel
{
	public id: number;
	public surveyId: number;
	public surveyQuestionAnswers: SurveyQuestionAnswerViewModelRead[];
}
export class SurveyResponseQuestionAnswerViewModel
{
	public surveyQuestionAnswerId: number;
}
export class SurveyResponseQuestionAnswerViewModelRead extends SurveyResponseQuestionAnswerViewModel
{
	public id: number;
	public surveyResponseQuestionId: number;
}
export class SurveyResponseQuestionViewModel
{
	public sortOrder: number;
	public surveyQuestionId: number;
	public surveyResponseQuestionAnswers: SurveyResponseQuestionAnswerViewModel[];
}
export class SurveyResponseQuestionViewModelRead extends SurveyResponseQuestionViewModel
{
	public id: number;
	public surveyResponseId: number;
}
export class SurveyResponseViewModel
{
	public surveyId: number;
	public userId?: number;
	public surveyDate: Date;
	public surveyResponseQuestions: SurveyResponseQuestionViewModel[];
}
export class SurveyResponseViewModelRead extends SurveyResponseViewModel
{
	public id: number;
	public userId: number;
}
export class SurveyResultAnswerViewModel
{
	public surveyQuestionAnswerId: number;
}
export class SurveyResultAnswerViewModelRead extends SurveyResultAnswerViewModel
{
	public id: number;
	public surveyResultId: number;
	public surveyQuestionAnswer: SurveyQuestionAnswerViewModelRead;
}
export class SurveyResultProgramViewModel
{
	public programId: number;
}
export class SurveyResultProgramViewModelRead extends SurveyResultProgramViewModel
{
	public id: number;
	public surveyResultId: number;
	public program: ProgramSummaryViewModelRead;
}
export class SurveyResultSearchParams
{
	public searchText: string;
	public surveyId?: number;
	public page: number;
	public size: number;
}
export class SurveyResultSummaryViewModel
{
	public surveyId: number;
	public sortOrder: number;
	public name: string;
	public description: string;
}
export class SurveyResultSummaryViewModelRead extends SurveyResultSummaryViewModel
{
	public id: number;
	public survey: SurveySummaryViewModelRead;
}
export class SurveyResultViewModel
{
	public surveyId: number;
	public sortOrder: number;
	public name: string;
	public description: string;
	public surveyResultAnswers: SurveyResultAnswerViewModel[];
	public surveyResultPrograms: SurveyResultProgramViewModelRead[];
}
export class SurveyResultViewModelRead extends SurveyResultViewModel
{
	public id: number;
	public surveyResultAnswers: SurveyResultAnswerViewModelRead[];
}
export class SurveySummaryViewModel
{
	public name: string;
	public description: string;
	public isActive: boolean;
}
export class SurveySummaryViewModelRead extends SurveySummaryViewModel
{
	public id: number;
}
export class SurveyViewModel
{
	public name: string;
	public description: string;
	public isActive: boolean;
	public startSurveyQuestionId?: number;
	public surveyQuestions: SurveyQuestionViewModel[];
}
export class SurveyViewModelRead extends SurveyViewModel
{
	public id: number;
	public surveyQuestions: SurveyQuestionViewModelRead[];
}
export class SurveyWorkflowDependencyViewModel
{
	public surveyQuestionAnswerId: number;
}
export class SurveyWorkflowDependencyViewModelRead extends SurveyWorkflowDependencyViewModel
{
	public id: number;
	public surveyWorkflowId: number;
}
export class SurveyWorkflowViewModel
{
	public sortOrder: number;
	public nextSurveyQuestionId?: number;
	public redirectURL: string;
	public surveyWorkflowDependencies: SurveyWorkflowDependencyViewModel[];
}
export class SurveyWorkflowViewModelRead extends SurveyWorkflowViewModel
{
	public id: number;
	public surveyQuestionAnswerId: number;
}
export class AcuteChronicHistoryViewModel
{
	public activityDate: Date;
	public acuteChronicWorkloadRatioPercentage?: number;
}
export class BasicReportViewModelRead
{
	public metricName: string;
	public value: number;
}
export class CoachAthleteGroupViewModel
{
	public userId?: number;
	public firstName: string;
	public lastName: string;
	public description: string;
}
export class DynamicReportFilterViewModel
{
	public coachId?: number;
}
export class SimpleDynamicReportViewModel
{
	public tableHeaders: string[];
	public tableData: any[][];
}
export class DynamicReportViewModel
{
	public tableHeaders: string[];
	public tableValues: DynamicReportRowViewModel[];
	public chartHeaders: string[];
	public chartData: any[][];
}
export class DynamicReportRowViewModel
{
	public userCredentialId: number;
	public userFullName: string;
	public tableCellValues: DataAndTrend[];
}
export class DateRangeViewModel
{
	public startDate: Date;
	public endDate: Date;
}
export class DataAndTrend
{
	public value?: number;
	public isUpTrend?: boolean;
}
export class TrainingLoadTargetViewModelRead
{
	public trainingLoadTargetLow: number;
	public trainingLoadTargetHigh: number;
	public isTrainingLoadTargetIncomplete: boolean;
}
export class TrainingVolumeReportViewModel
{
	public tableHeaders: string[];
	public tableValues: TrainingVolumeReportRowViewModel[];
	public chartHeaders: string[];
	public timeChartData: any[][];
	public milesChartData: any[][];
}
export class TrainingVolumeReportRowViewModel
{
	public userCredentialId: number;
	public userFullName: string;
	public tableCellValues: TrainingVolumeTableCells[];
}
export class TrainingVolumeTableCells
{
	public totalTime: number;
	public timePercentageChange?: number;
	public isUpTrendTime?: boolean;
	public totalMiles: number;
	public milesPercentageChange?: number;
	public isUpTrendMiles?: boolean;
}
export class CreateOrganizationViewModel
{
	public organizationName: string;
	public firstName: string;
	public lastName: string;
	public email: string;
	public dongleIds: string[];
	public isLevel2: boolean;
	public complimentarySubscription: boolean;
	public organizationType: OrganizationType;
	public complimentarySubscriptionEndDate?: Date;
}
export class OrganizationListViewModelRead
{
	public id: number;
	public name: string;
	public dongleIds: string[];
	public isOrgAdminPasswordSet: boolean;
	public runnerSlots: number;
	public subscriptionPrice: number;
	public subscriptionNextInvoicePrice?: number;
	public runnerCount: number;
	public isMonthly: boolean;
	public activeSubscription: boolean;
	public complimentarySubscription: boolean;
	public threeDAssessmentCount: number;
	public trialDaysLeft: number;
	public createdDate: Date;
	public brandName: string;
	public fromPlanBuilder: boolean;
}
export class PricingTierViewModel
{
	public organizationId: number;
	public qty: number;
	public monthlyPriceLongTerm: number;
	public yearlyPriceLongTerm: number;
	public monthlyPriceNextInvoice: number;
	public yearlyPriceNextInvoice: number;
	public product: RunDnaProduct;
}
export class OrganizationBillingViewModel
{
	public id: number;
	public last4OfCard: string;
	public stripeCustomerId: string;
	public runnerSlotSubscriptionId: string;
	public runnerSlots: number;
	public subscriptionPrice: number;
	public subscriptionNextInvoicePrice?: number;
	public subscriptionNextInvoicePriceEndDate?: Date;
	public cardBrand: string;
	public runnerCount: number;
	public isMonthly: boolean;
	public subscriptionEndDate?: Date;
	public complimentarySubscription: boolean;
	public complimentarySubscriptionEndDate?: Date;
	public product: RunDnaProduct;
	public skipBillingUpdates: boolean;
	public balance?: number;
}
export class ReplaceCouponViewModel
{
	public couponCode: string;
}
export class OrganizationInvoiceViewModel
{
	public periodStart: Date;
	public periodEnd: Date;
	public billingReason: string;
	public status: string;
	public total: number;
	public invoicePdf: string;
}
export class OrganizationDiscountViewModel
{
	public id: string;
	public description: string;
	public durationDescription: string;
	public displayName: string;
	public expires?: Date;
	public isCouponValid: boolean;
}
export class OrganizationSubscriptionViewModel
{
	public cancelAtPeriodEnd: boolean;
	public currentPeriodEnd: Date;
	public currentPeriodStart: Date;
}
export class OrganizationViewModel
{
	public name: string;
	public organizationURL: string;
	public avatar: string;
	public base64Image: string;
	public street1: string;
	public street2: string;
	public city: string;
	public stateCode: string;
	public postalCode: string;
	public dongleIds: string[];
	public isLevel2: boolean;
	public trialExpires: Date;
	public complimentarySubscription: boolean;
	public complimentarySubscriptionEndDate?: Date;
	public activeSubscription: boolean;
	public organizationType: OrganizationType;
	public marketplaceURLSlug: string;
	public marketplaceLogo: string;
	public marketplaceDescription: string;
	public marketplaceOrganizationPercentage?: number;
	public marketplaceStatus: MarketplaceStatus;
	public marketplaceCategoryDisplayType?: MarketplaceCategoryDisplayType;
	public featuredInGlobalMarketplace: boolean;
	public stripeConnectedAccountId: string;
	public apI_Id?: number;
	public requestDailyStatus: boolean;
}
export class OrganizationViewModelRead extends OrganizationViewModel
{
	public id: number;
	public runnerSlotSubscriptionId: string;
	public subscriptionEndDate?: Date;
}
export class NotificationTypeRoleViewModelRead
{
	public id: number;
	public notificationType: NotificationType;
	public userRoles: UserRole;
}
export class UserCredentialNotificationViewModel
{
	public userCredentialId: number;
	public notificationType: NotificationType;
	public isActive: boolean;
}
export class UserCredentialNotificationViewModelRead extends UserCredentialNotificationViewModel
{
	public id: number;
}
export class DailyStatusSettingsViewModel
{
	public userCredentialId: number;
	public requestDailyStatus: boolean;
}
export class ExternalWorkoutChartData
{
	public timerDuration: number[];
	public value: number;
}
export class ExternalWorkoutLapChartData
{
	public lapNumber: number;
	public duration: number;
	public distance: number;
	public pace: number;
	public speed: number;
}
export class ExternalWorkoutPaceChartData
{
	public timerDuration: number[];
	public valueAsTime: number[];
}
export class ExternalWorkoutRouteCoordinatesViewModelRead
{
	public lat: number;
	public lng: number;
}
export class ExternalWorkoutSummaryViewModelRead
{
	public routeCoordinates: ExternalWorkoutRouteCoordinatesViewModelRead[];
	public distance: number;
	public durationInSeconds: number;
	public averagePace: number;
	public totalElevationGain: number;
	public activeKilocalories: number;
	public averageHeartRateInBeatsPerMinute: number;
	public activityType: string;
	public polyline: string;
	public averageSpeed: number;
	public activityTypeEnum: ActivityType;
	public stravaObjectId?: number;
}
export class ExternalDailyChartData
{
	public eventDate: Date;
	public value: number;
}
export class ClientSummaryDisplayViewModel
{
	public id: number;
	public fullName: string;
	public nextScheduledEventDays?: number;
	public completionPercentage?: number;
	public coachUserId?: number;
	public dailyStatusPercentEffortMin: number;
	public dailyStatusPercentEffortMax: number;
	public dailyStatusPainDays: number;
	public lastScheduledWorkoutDate?: Date;
}
export class CoachDashboardViewModel
{
	public coachUserId?: number;
	public dashboard: DashboardViewModel;
}
export class TeamDashboardViewModel
{
	public teamId: number;
	public dashboard: DashboardViewModel;
}
export class ClientAcuteChronicPercentageViewModel
{
	public id: number;
	public fullName: string;
	public acuteChronicPercentage: number;
}
export class DashboardViewModel
{
	public painClients: ClientSummaryDisplayViewModel[];
	public lowCompletionClients: ClientSummaryDisplayViewModel[];
	public eventClients: ClientSummaryDisplayViewModel[];
	public highACWRClients: ClientAcuteChronicPercentageViewModel[];
}
export class AFFitnessAssessmentViewModel
{
	public gender: Gender;
	public age: number;
	public notes: string;
	public cardioExerciseType: AFFitnessAssessmentCardioExerciseType;
	public muscular1ExerciseType: AFFitnessAssessmentMuscular1ExerciseType;
	public muscular2ExerciseType: AFFitnessAssessmentMuscular2ExerciseType;
	public cardioValue: number;
	public muscular1Value: number;
	public muscular2Value: number;
	public dateTime: Date;
	public cardioPoints: number;
	public muscular1Points: number;
	public muscular2Points: number;
	public compositePoints: number;
	public cardioMinimumMet: boolean;
	public muscular1MinimumMet: boolean;
	public muscular2MinimumMet: boolean;
	public healthRiskCategory: AFHealthRiskCategory;
	public compositeScoreCategory: AFCompositeScoreCategory;
}
export class AFFitnessAssessmentViewModelRead extends AFFitnessAssessmentViewModel
{
	public id: number;
	public userId: number;
	public coachId?: number;
	public status: AssessmentStatus;
	public pass: boolean;
}
export class AssessmentDetailViewModel
{
	public id: number;
	public dateTime: Date;
	public movementCategory: MovementCategory;
	public handicapScore: number;
	public impairmentCategory?: ImpairmentCategory;
	public notes: string;
	public sections: AssessmentSectionViewModel[];
}
export class AssessmentNotesViewModel
{
	public id: number;
	public assessmentType: AssessmentType;
	public notes: string;
}
export class AssessmentNotesViewModelRead extends AssessmentNotesViewModel
{
	public dateTime: Date;
}
export class AssessmentSectionViewModel
{
	public sectionName: string;
	public categoryResults: AssessmentCategoryResultViewModel[];
}
export class LoadingLevelsAssessmentViewModel
{
	public lateralStepDownAblePainfreeLeft: boolean;
	public lateralStepDownAblePainfreeRight: boolean;
	public lateralStepDownAlignmentLeft: boolean;
	public lateralStepDownAlignmentRight: boolean;
	public lateralStepDownSymmetry: boolean;
	public qualifierBilateralJumpRopeAblePainfree: boolean;
	public stationaryJumpBilateralAblePainfree: boolean;
	public stationaryJumpBilateralAlignment: boolean;
	public stationaryJumpBilateralSymmetry: boolean;
	public depthJumpOverBarrierAblePainfree: boolean;
	public depthJumpOverBarrierAlignment: boolean;
	public depthJumpOverBarrierSymmetry: boolean;
	public qualifierUnilateralJumpRopeAblePainfreeLeft: boolean;
	public qualifierUnilateralJumpRopeAblePainfreeRight: boolean;
	public stationaryJumpUnilateralAblePainfreeLeft: boolean;
	public stationaryJumpUnilateralAblePainfreeRight: boolean;
	public stationaryJumpUnilateralAlignmentLeft: boolean;
	public stationaryJumpUnilateralAlignmentRight: boolean;
	public stationaryJumpUnilateralSymmetry: boolean;
	public tripleHopUnilateralAblePainfreeLeft: boolean;
	public tripleHopUnilateralAblePainfreeRight: boolean;
	public tripleHopUnilateralAlignmentLeft: boolean;
	public tripleHopUnilateralAlignmentRight: boolean;
	public tripleHopUnilateralSymmetry: boolean;
	public notes: string;
	public lateralStepDownLeftVideoId?: number;
	public lateralStepDownRightVideoId?: number;
	public stationaryJumpBilateralVideoId?: number;
	public depthJumpOverBarrierVideoId?: number;
	public stationaryJumpUnilateralLeftVideoId?: number;
	public stationaryJumpUnilateralRightVideoId?: number;
	public tripleHopUnilateralLeftVideoId?: number;
	public tripleHopUnilateralRightVideoId?: number;
}
export class LoadingLevelsAssessmentViewModelRead extends LoadingLevelsAssessmentViewModel
{
	public id: number;
	public userId: number;
	public coachId: number;
	public dateTime: Date;
	public status: AssessmentStatus;
	public levelAssigned?: number;
}
export class TwoDimensionalAssessmentImageViewModel
{
	public twoDimensionalAssessmentId: number;
	public imageLocatorName: string;
	public description: string;
	public notes: string;
}
export class TwoDimensionalAssessmentImageViewModelRead extends TwoDimensionalAssessmentImageViewModel
{
	public id: number;
}
export class TwoDimensionalAssessmentVideoViewModel
{
	public twoDimensionalAssessmentId: number;
	public videoId: number;
	public description: string;
}
export class TwoDimensionalAssessmentVideoViewModelRead extends TwoDimensionalAssessmentVideoViewModel
{
	public id: number;
	public video: VideoViewModel;
}
export class TwoDimensionalAssessmentViewModel
{
	public impairmentCategory?: ImpairmentCategory;
	public title: string;
	public assessmentNotes: string;
	public generalCadence?: TwoDimensionalAssessmentResult;
	public generalBouncing?: TwoDimensionalAssessmentResult;
	public generalGroundContactTime?: TwoDimensionalAssessmentResult;
	public generalOther1?: TwoDimensionalAssessmentResult;
	public generalOther1Text: string;
	public generalOther2?: TwoDimensionalAssessmentResult;
	public generalOther2Text: string;
	public generalNotes: string;
	public initialFootPosition?: TwoDimensionalAssessmentResult;
	public initialKneePosition?: TwoDimensionalAssessmentResult;
	public initialHipPosition?: TwoDimensionalAssessmentResult;
	public initialMalleolarLine?: TwoDimensionalAssessmentResult;
	public initialHipSeparationAngle?: TwoDimensionalAssessmentResult;
	public initialOther?: TwoDimensionalAssessmentResult;
	public initialOtherText: string;
	public initialNotes: string;
	public midFootPosition?: TwoDimensionalAssessmentResult;
	public midKneePosition?: TwoDimensionalAssessmentResult;
	public midHipPosition?: TwoDimensionalAssessmentResult;
	public midKneeWindow?: TwoDimensionalAssessmentResult;
	public midPosture?: TwoDimensionalAssessmentResult;
	public midOther?: TwoDimensionalAssessmentResult;
	public midOtherText: string;
	public midNotes: string;
	public swingFootPosition?: TwoDimensionalAssessmentResult;
	public swingKneePosition?: TwoDimensionalAssessmentResult;
	public swingHipPosition?: TwoDimensionalAssessmentResult;
	public swingPosture?: TwoDimensionalAssessmentResult;
	public swingOther?: TwoDimensionalAssessmentResult;
	public swingOtherText: string;
	public swingNotes: string;
	public videos: TwoDimensionalAssessmentVideoViewModelRead[];
	public images: TwoDimensionalAssessmentImageViewModelRead[];
}
export class TwoDimensionalAssessmentViewModelRead extends TwoDimensionalAssessmentViewModel
{
	public id: number;
	public userId: number;
	public coachId: number;
	public dateTime: Date;
	public status: AssessmentStatus;
}
export enum ImpairmentCategory {
	Uncategorized = 0,
	OverstriderCadence = 1,
	OverstriderKneeDrive = 2,
	OverstriderCollapsing = 3,
	OverstriderGluteAmnesiac = 4,
	OverstriderGeneral = 5,
	GeneralCadence = 6,
	CollapserCPD = 7,
	CollapserBouncer = 8,
	CollapserHip = 9,
	Bouncer = 10,
	GluteAmnesiac = 11,
	Weaver = 12,
	Collapser = 13
}
export enum AssessmentResult {
	Able = 1,
	Unable = 2,
	AbleWithPain = 3,
	UnableWithPain = 4,
	ActiveLimitation = 5,
	PassiveLimitation = 6,
	ActiveLimitationWithPain = 7,
	PassiveLimitationWithPain = 8
}
export enum AccountStatus {
	FullAccount = 1,
	PendingSetup = 2
}
export enum Gender {
	Other = 0,
	Male = 1,
	Female = 2
}
export enum LevelOfRunning {
	Recreational = 1,
	HighSchool = 2,
	College = 3,
	Professional = 4
}
export enum RunningExperience {
	Beginner = 1,
	OneToTwo = 2,
	ThreeToFive = 3,
	FiveToSeven = 4,
	SevenToTen = 5,
	TenPlus = 6
}
export enum ClientStatus {
	Active = 1,
	InActive = 2,
	Invited = 3
}
export enum VideoType {
	None = 0,
	Upload = 1,
	Public = 2
}
export enum WorkoutItemType {
	Exercise = 1,
	Circuit = 2,
	Cardio = 3,
	Interval = 4,
	GarminWorkoutStep = 5,
	GarminWorkoutRepeatStep = 6
}
export enum WorkoutType {
	Strength = 1,
	Cardio = 2,
	GarminRunning = 3,
	GarminCycling = 4,
	GarminLap_Swimming = 5
}
export enum CardioIntensityType {
	HeartRateZone = 1,
	Pace = 2,
	RateOfPerceivedExertion = 3,
	VDOTPace = 4
}
export enum CardioType {
	Work = 1,
	Recovery = 2
}
export enum CardioDistanceUOM {
	Miles = 1,
	Kilometers = 2,
	Meters = 3
}
export enum UserRole {
	Runner = 1,
	AssistantCoach = 2,
	HeadCoach = 4,
	GlobalCoach = 8,
	Administrator = 16
}
export enum MovementCategory {
	Uncategorized = 0,
	SquatDorsiflexion = 1,
	BackBend = 2,
	ToeTouchPosteriorChain = 3,
	ToeTouchSpine = 4,
	SquatHipMobility = 5,
	UHBE = 6,
	SingleLegSitToStand = 7,
	BalanceHipCore = 8,
	BalanceFootAnkle = 9,
	CalfRaises = 10,
	StrongFoot = 11,
	RotationUE = 12,
	RotationLE = 13,
	SidePlank = 14
}
export enum ExerciseEquipment {
	AerobicPlyoStep = 1,
	Barbell = 2,
	Box = 3,
	Dumbbell = 4,
	FoamRoller = 5,
	HeavyBands = 6,
	Kettlebell = 7,
	LacrosseBall = 8,
	LargePhysioBall = 9,
	LoopBands = 10,
	MedicineBall = 11,
	PullUpBar = 12,
	TRXSuspensionStraps = 13,
	WeightedVest = 14,
	YogaBlock = 15
}
export enum CategoryScore {
	U = 0,
	G = 1,
	GF = 2,
	FG = 3,
	F = 4,
	FP = 5,
	PF = 6,
	P = 7
}
export enum AssessmentResultShort {
	Able = 1,
	Unable = 2
}
export enum AssessmentStatus {
	InProgress = 0,
	Completed = 1,
	ProgramsAssigned = 2
}
export enum AssessmentType {
	GaitAnalysis = 1,
	RRA = 2,
	LoadingLevels = 3,
	TwoDimensional = 4,
	USAFFitnessAssessment = 5,
	GaitReport = 6,
	GaitCapability = 7
}
export enum GaitScoreDescription {
	Green = 1,
	Yellow = 2,
	Orange = 3,
	Red = 4
}
export enum GaitProgramPrimaryFactor {
	Cadence = 1,
	StrideLength = 2,
	DoubleSupport = 3,
	DutyFactor = 4,
	StepWidth = 5,
	StanceTime = 6,
	HipFlexionIc = 7,
	KneeFlexionIc = 8,
	AnkleFlexionIc = 9,
	PelvicRotationIc = 10,
	TrunkRotationIc = 11,
	KneeFlexionMs = 12,
	PeakHipAdd = 13,
	PeakHipIr = 14,
	PeakAnklePronation = 15,
	PelvicDropMS = 16,
	PelvicTiltTo = 17,
	PeakFootEr = 18,
	HipExtensionTo = 19,
	AnkleSupination = 20,
	KneeFlexionIcAsym = 21,
	PelvicRotationIcAsym = 22,
	PelvicDropMSAsym = 23,
	HipAddAsym = 24,
	KneeFlexionMsAsym = 25,
	HipFlexionTo = 26,
	AnkleFlexionTo = 27,
	HipIr = 28,
	AnklePronation = 29
}
export enum PainType {
	Rest = 1,
	Activity = 2,
	Both = 3
}
export enum TwoDimensionalAssessmentResult {
	NeedsAttention = 1,
	Satisfactory = 2
}
export enum ClientDayEventType {
	Workout = 1,
	RestDay = 2,
	Event = 3,
	Task = 4,
	GarminSyncedWorkout = 5,
	ManualWorkout = 6,
	StravaSyncedWorkout = 7,
	QuickWorkout = 8
}
export enum DayOfWeekFlag {
	Sunday = 1,
	Monday = 2,
	Tuesday = 4,
	Wednesday = 8,
	Thursday = 16,
	Friday = 32,
	Saturday = 64
}
export enum RecurringEventFrequency {
	Never = 0,
	Daily = 1,
	Weekly = 2
}
export enum WorkoutCategory {
	Warmup = 1,
	Cardio = 2,
	Mobility = 3,
	Core = 4,
	Strength = 5,
	Rehab = 6
}
export enum AFHealthRiskCategory {
	LowRisk = 1,
	ModerateRisk = 2,
	HighRisk = 3
}
export enum AFCompositeScoreCategory {
	Unsatisfactory = 1,
	Satisfactory = 2,
	Excellent = 3
}
export enum AFFitnessAssessmentCardioExerciseType {
	Run = 1,
	HamrShuttle = 2
}
export enum AFFitnessAssessmentMuscular1ExerciseType {
	Pushup = 1,
	HandReleasePushup = 2
}
export enum AFFitnessAssessmentMuscular2ExerciseType {
	Situp = 1,
	CrossLegReverseCrunch = 2,
	ForearmPlank = 3
}
export enum OrganizationType {
	General = 0,
	HasUSAFFitnessAssessment = 1
}
export enum NotificationType {
	DailyWorkout = 1,
	ProgramAssigned = 2,
	WeeklyAthleteReport = 3,
	SyncedWorkoutRPE = 5,
	WeeklyTrainingLoadTarget = 6,
	InAppMessage = 7
}
export enum TrainingLoadPeriodType {
	Maintenance = 1,
	Base = 2,
	Build = 3,
	Peak = 4,
	Rest = 5,
	Taper = 6,
	Race = 7,
	Skip = 8
}
export enum VDOTPaceType {
	Easy = 1,
	Marathon = 2,
	Threshold = 3,
	Interval = 4,
	Repetition = 5
}
export enum RaceDistanceType {
	FiveK = 1,
	TenK = 2,
	HalfMarathon = 3,
	Marathon = 4,
	Custom = 5
}
export enum CurrentMessageMode {
	SpecificUser = 0,
	AllAthletes = 1,
	AllCoaches = 2,
	SpecificTeam = 3
}
export enum ActivityType {
	Running = 1,
	Biking = 2,
	Swimming = 3,
	Walking = 4,
	StrengthTraining = 5,
	Other = 6
}
export enum CalendarType {
	Client = 1,
	Team = 2
}
export enum CalendarContextMenuOptions {
	None = 0,
	View = 1,
	Edit = 2,
	Delete = 4,
	ToggleComplete = 8,
	Copy = 16
}
export enum TeamUserRole {
	None = 0,
	Runner = 1,
	Coach = 2
}
export enum SurveyQuestionType {
	OneAnswerOnly = 1,
	MultipleAnswersOptional = 2,
	MultipleAnswersRequired = 3
}
export enum ProgramCategory {
	Cardio = 1,
	Mobility = 2,
	Rehab = 4,
	Strength = 8
}
export enum MarketplaceStatus {
	None = 0,
	PendingStripeSetup = 1,
	Active = 2
}
export enum MarketplaceCategoryDisplayType {
	ByCoach = 1,
	ByKeyword = 2
}
export enum AthleteType {
	Coached = 1,
	Marketplace = 2
}
export enum GlobalMarketplaceExperienceLevel {
	All = 1,
	Beginner = 2,
	Intermediate = 3,
	Advanced = 4,
	Elite = 5
}
export enum GlobalMarketplaceProgramType {
	FiveK = 1,
	TenK = 2,
	HalfMarathon = 3,
	Marathon = 4,
	Ultra = 5,
	TrackLess800m = 6,
	TrackGreater800m = 7,
	Triathlon = 8,
	BaseBuilding = 9,
	RehabInjury = 10,
	StrengthCore = 11,
	OtherDistance = 12
}
export enum GarminPoolLengthUnit {
	METER = 1,
	YARD = 2
}
export enum WorkoutItemGarminRepeatType {
	REPEAT_UNTIL_STEPS_CMPLT = 1
}
export enum GarminWorkoutStepIntensity {
	WARMUP = 1,
	ACTIVE = 2,
	RECOVERY = 3,
	REST = 4,
	COOLDOWN = 5
}
export enum GarminWorkoutStepDurationType {
	TIME = 1,
	DISTANCE = 2,
	OPEN = 3
}
export enum GarminWorkoutStepDurationValueType {
	MILE = 1,
	KILOMETER = 2,
	METER = 3,
	YARD = 4
}
export enum GarminWorkoutStepTargetType {
	SPEED = 1,
	PACE = 2,
	HEART_RATE_ZONE = 3,
	HEART_RATE_CUSTOM = 4,
	POWER_ZONE = 5,
	POWER_CUSTOM = 6
}
export enum GarminWorkoutStepTargetValueType {
	MINS_PER_MILE = 1,
	MINS_PER_KILOMETER = 2,
	MILES_PER_HOUR = 3,
	KILOMETERS_PER_HOUR = 4,
	MIN_PER_100_YARDS = 5,
	MIN_PER_100_METERS = 6
}
export enum GarminWorkoutStepStrokeType {
	BACKSTROKE = 1,
	BREASTSTROKE = 2,
	DRILL = 3,
	BUTTERFLY = 4,
	FREESTYLE = 5,
	MIXED = 6,
	IM = 7
}
export enum GarminWorkoutStepEquipmentType {
	NONE = 1,
	SWIM_FINS = 2,
	SWIM_KICKBOARD = 3,
	SWIM_PADDLES = 4,
	SWIM_PULL_BUOY = 5,
	SWIM_SNORKEL = 6
}
export enum MeasurementSystem {
	Imperial = 1,
	Metric = 2
}
export enum ExerciseResistanceType {
	Resistance = 1,
	Weight = 2,
	Hold = 3
}
export enum GaitCapabilityCategory {
	Uncategorized = 0,
	KneelingDorsiflexion = 1,
	Thomas = 2,
	ASLR = 3,
	ToeTouch = 4,
	SitToStand = 5,
	CalfRaise = 6,
	PaperGrip = 7,
	UHBE = 8,
	FourStageBalance = 9,
	Hurdle = 10,
	HalluxExtension = 11,
	ToeSplay = 12,
	HalluxABD = 13,
	Rotation = 14
}
export enum WeightUOM {
	Pounds = 1,
	Kilograms = 2
}
export enum JournalType {
	General = 1,
	Workout = 2,
	DailyStatus = 3
}
export enum ComparisonDirectionEnum {
	Equal = 0,
	Up = 1,
	Down = 2
}
export enum ComparisonResultEnum {
	Equal = 0,
	Better = 1,
	Worse = 2
}
export enum WalkingCategory {
	Uncategorized = 0,
	SpeedCadence = 1,
	Symmetry = 2,
	Balance = 3,
	FootAnkle = 4,
	Knee = 5,
	Hip = 6,
	Posture = 7
}
export enum RunDnaProduct {
	Undefine = 0,
	RunnerCount = 1,
	Helix = 2
}
