import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, forkJoin } from 'rxjs';
import { OrganizationBillingViewModel, OrganizationInvoiceViewModel, ReplaceCouponViewModel, RunDnaProduct, ValidateCouponViewModel } from 'src/app/_models/generatedModels';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { OrganizationService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { ActivateSubscription } from './activate-subscription/activate-subscription.component';
import { UpdateCard } from './update-card/update-card.component';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'bxl-admin-billing',
  templateUrl: 'admin-billing.component.html',
})
export class AdminBillingComponent implements OnInit
{
  initialized = false;
  organization: OrganizationBillingViewModel;
  invoices: OrganizationInvoiceViewModel[];
  subscriptionNote: string;
  RunDnaProduct = RunDnaProduct;

  couponCode: string;
  coupon: string;
  isCouponValid: boolean = false;
  couponDescription: string;
  couponDuration: string;
  pricingIsBeingRecalculated: boolean = false;
  couponIsBeingValidated: boolean = false;
  couponSubject: Subject<ValidateCouponViewModel> = new Subject<ValidateCouponViewModel>();
  constructor(private breadcrumbs: BreadcrumbsService, private organizationService: OrganizationService, private modalService: NgbModal, private toastr: ToasterService, private auth: AuthenticationService) { }

  ngOnInit(): void
  {
    this.breadcrumbs.SetSecondaryBreadcrumb('Billing', 'billing', []);

    forkJoin([this.organizationService.getOrganizationBillingDetails(), this.organizationService.getInvoicesForOrganization()]).subscribe((results) =>
    {
      this.organization = results[0];
      this.invoices = results[1];

      if (this.organization.complimentarySubscription && !this.organization.runnerSlotSubscriptionId) {
        this.subscriptionNote = 'Your organization has a complimentary subscription';
        this.subscriptionNote += this.organization.complimentarySubscriptionEndDate ? ' that expires on ' + formatDate(this.organization.complimentarySubscriptionEndDate, 'MMM d, y', 'en-US') : '';
      }

      this.initialized = true;
    });

    this.couponSubject.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((model: ValidateCouponViewModel) =>
      {
        if (!this.couponCode) {

          this.isCouponValid = false;
          this.couponDescription = '';
          this.couponDuration = '';
          this.couponIsBeingValidated = false;
          return;
        }

        let validationModel = new ValidateCouponViewModel();
        validationModel.isMonthlyPrice = this.organization.isMonthly;
        validationModel.coupon = this.couponCode;

        this.organizationService.validateCoupon(validationModel).subscribe(result =>
        {
          this.couponDescription = result.description;

          this.couponDuration = result.duration;

          if (result.isValid) {
            this.isCouponValid = true;

            this.couponIsBeingValidated = false;
            return;
          }

          this.couponIsBeingValidated = false;

          this.isCouponValid = false;
        },
          (error) =>
          {
            this.couponDuration = '';
            this.couponDescription = error.error;
            this.isCouponValid = false;
            this.couponIsBeingValidated = false;
          }
        );
      });
  }

  activateSubscription()
  {
    if (this.organization.runnerSlotSubscriptionId && this.organization.subscriptionEndDate && this.organization.last4OfCard) {
      this.toastr.confirmDialog('Are you sure you want to re-activate your organization\'s subscription? (We will use the card on file)', 'Re-Activate Subscription', 'Re-Activate', 'Cancel').subscribe(result =>
      {
        if (result) {
          this.organizationService.reActivateExistingSubscription().subscribe(results =>
          {
            this.organization = results;
            // clear the user profile cache so that the user's subscription status is updated
            this.auth.fetchUserProfile(false, true).subscribe();
            this.updateOrganizationBillingDetails()
            this.updateInvoiceList();
            this.toastr.success('Subscription re-activated', 'Success');
          },
           (error) =>
           {
             this.toastr.error("Subscription cannot be re-activated. Please, contact support@rundna.com to resolve the problem.", 'Error');
           });
        }
      });
    } else {
      const modalRef = this.modalService.open(ActivateSubscription, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
      modalRef.componentInstance.organization = this.organization;
      modalRef.result.then(
        (company: OrganizationBillingViewModel) =>
        {
          this.organization = company;
          this.updateOrganizationBillingDetails();
          this.updateInvoiceList();
        },
        (reason) => { }
      );
    }
  }

  updateCard()
  {
    const modalRef = this.modalService.open(UpdateCard, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    modalRef.result.then(
      (company: OrganizationBillingViewModel) =>
      {
        this.organization = company;
      },
      (reason) => { }
    );
  }

  replaceCoupon()
  {
    var confirmationText = 'Are you sure you want to proceed? Applying this coupon will replace any existing coupons on your subscription, and will reset the start of the billing cycle to today, which will alter your next payment date. ';

    if (this.organization.complimentarySubscription && this.couponDuration == 'repeating') {
      confirmationText += '<br/>Please note, you currently have an active complimentary subscription. However, if you apply a coupon now, it will take effect immediately, potentially resulting in a partial loss of the coupon\'s benefits. Therefore, it is recommended to apply the coupon at the end of the complimentary subscription period.';
    }

    this.toastr.confirmModal('<p class="text-danger font-weight-400">' + confirmationText +  '</p>', 'Apply coupon', 'Apply', 'Cancel').subscribe(result =>
    {
      if (result) {

        this.pricingIsBeingRecalculated = true;

        let model = new ReplaceCouponViewModel();
        model.couponCode = this.couponCode;
        this.organizationService.replaceCoupon(model).subscribe(result =>
        {
          forkJoin([this.organizationService.getOrganizationBillingDetails(), this.organizationService.getInvoicesForOrganization()]).subscribe((results) =>
          {
            this.organization = results[0];
            this.invoices = results[1];

            if (this.organization.complimentarySubscription && !this.organization.runnerSlotSubscriptionId) {
              this.subscriptionNote = 'Your organization has a complimentary subscription';
              this.subscriptionNote += this.organization.complimentarySubscriptionEndDate ? ' that expires on ' + formatDate(this.organization.complimentarySubscriptionEndDate, 'MMM d, y', 'en-US') : '';
            }

            this.couponCode = '';
            this.toastr.success('Coupon has been applied', 'Success');
            this.pricingIsBeingRecalculated = false;

          });
        },
          (error) =>
          {
            this.couponDescription = error.error;
            this.toastr.error('Coupon has not been applied', 'Error');
            this.isCouponValid = false;
            this.pricingIsBeingRecalculated = false;
          });

        return;
      }
    });
  }

  validateCoupon()
  {
   this.couponIsBeingValidated = true;

    let model = new ValidateCouponViewModel();
    model.coupon = this.couponCode;
    model.isMonthlyPrice = this.organization.isMonthly;

    this.couponSubject.next(model);
  }

  cancelSubscription()
  {
    this.organizationService.retrieveSubscription().subscribe(result =>
    {
      var endDate = new Date(result.currentPeriodEnd);

      var warnText = '';

      if (this.organization.complimentarySubscription && !this.organization.complimentarySubscriptionEndDate) {
        warnText = 'Are you sure you want to cancel your organization\'s subscription? You will continue to have access with unlimited free trial';

      }
      else {
        warnText = 'Are you sure you want to cancel your organization\'s subscription? You will continue to have access until ' + formatDate(endDate, 'MMM d, y', 'en-US') + ' when your billing cycle ends.' +
          '<p class="text-danger font-weight-400">After the subscription is canceled and the billing cycle ends, all scheduled athlete and team calendar data for future dates (date after end of billing cycle) will be permanently deleted. Historical calendar data will remain and can be accessed again by re-starting the subscription.</p>';
      }
    
     
      this.toastr.confirmModal(warnText, 'Cancel Subscription', 'Cancel Subscription', 'Cancel').subscribe(result =>
        {
          if (result) {
            this.organizationService.cancelSubscription().subscribe(results =>
            {
              this.organization = results;
              this.updateInvoiceList();
              this.toastr.success('Subscription canceled', 'Success');
            },
              (error) =>
              {
                this.toastr.error("Subscription cannot be canceled. Please, contact support@rundna.com to resolve the problem.", 'Error');
              });
          }
        });
    },
      (error) =>
      {
        this.toastr.error("Subscription cannot be canceled. Please, contact support@rundna.com to resolve the problem.", 'Error');
      });

  }

  getPricingDetails()
  {
    let description = 'Price: ';
    let interval = '';

    if (this.organization.isMonthly) {
      interval = "/month";
    }
    else {
      interval = "/year";
    }
    
    if (this.organization.subscriptionNextInvoicePrice === null) {
      description += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.organization.subscriptionPrice) + interval;
      return description;
    }

    if (this.organization.subscriptionEndDate) {
      description += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.organization.subscriptionNextInvoicePrice) + interval;
      return description;
    }

    if (this.organization.complimentarySubscription && this.organization.complimentarySubscriptionEndDate > this.organization.subscriptionNextInvoicePriceEndDate) {
      description += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.organization.subscriptionPrice) + interval;

      return description;
    }

    description += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.organization.subscriptionNextInvoicePrice) + interval;

    if (this.organization.subscriptionNextInvoicePrice != this.organization.subscriptionPrice) {
      description += " until " + formatDate(this.organization.subscriptionNextInvoicePriceEndDate, 'MMM d y', 'en-US') + ", then " + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.organization.subscriptionPrice) + interval;
    }

    return description;
  }

  updateInvoiceList()
  {
    this.organizationService.getInvoicesForOrganization().subscribe(result =>
    {
      this.invoices = result;
    });
  }


  updateOrganizationBillingDetails()
  {

    this.organizationService.getOrganizationBillingDetails().subscribe(result =>
    {
      this.organization = result;
    });
  }
}
