import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';
import { OrganizationService, SuperUserService, UserService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientStatus, CreateOrganizationViewModel, OrganizationType, OrganizationViewModel, OrganizationViewModelRead, UserProfileViewModel } from 'src/app/_models/generatedModels';
import { ValidatorHelper } from 'src/app/_helpers/validatorHelper.module';
import { forkJoin, timer } from 'rxjs';
import { CheckboxOption } from 'src/app/_models/models';
import { Enums } from 'src/app/_models/generatedEnums';
import { dynamicSort } from 'src/app/_helpers/extensions.module';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminUserAddEditModalComponent } from 'src/app/_components/admin-user-add-edit-modal/admin-user-add-edit-modal.component';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { map, switchMap } from 'rxjs/operators';
import { formatDate } from '@angular/common';

@Component({
  selector: 'bxl-add-edit-organization',
  templateUrl: 'add-edit-organization.component.html',
})
export class AddEditOrganizationComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  organizationId: number;
  organization: OrganizationViewModelRead;
  listUrl: string = '/superuser/organizations';
  dongleIds: FormArray;
  organizationTypes: CheckboxOption[] = [];
  organizationTypeEnum = Enums.OrganizationTypeEnum;
  organizationTypeFlag = OrganizationType;
  clientStatus = ClientStatus;
  clientStatusEnum = Enums.ClientStatusEnum;
  marketplaceStatusEnum = Enums.MarketplaceStatusEnum;

  users: UserProfileViewModel[];
  userRoleEnum = Enums.UserRoleEnum;

  constructor(private organizationService: OrganizationService, private fb: FormBuilder, private toastr: ToasterService, private router: Router, private breadcrumbs: BreadcrumbsService,
    private validatorHelper: ValidatorHelper, private route: ActivatedRoute, private superUserService: SuperUserService, private modalService: NgbModal, private auth: AuthenticationService,
    private usersService: UserService) {}

  ngOnInit(): void {
    this.organizationId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    this.breadcrumbs.SetSecondaryBreadcrumb('Organizations', 'organizations', []);
    this.breadcrumbs.AppendBreadcrumb((this.organizationId ? 'Edit ' : 'Add ') + 'Organization', this.router.url, []);

    this.organizationTypeEnum.forEach((value, key) => {
      if (key != 0) {
        this.organizationTypes.push(new CheckboxOption(value, value, key, false));
      }
    });

    if (this.organizationId) {
      this.organizationService.getOrganization(this.organizationId).subscribe((result) => {
        this.organization = result;
        this.setupForm();
      });

      this.getOrgUsers();
    } else {
      this.organization = new OrganizationViewModelRead();

      this.setupForm();
    }
  }

  setupForm() {
    this.organizationTypes.forEach(type => {
      if (this.organization.organizationType & this.organizationTypeFlag[type.name]) {
        type.selected = true;
      }
    });

    this.formGroup = this.fb.group({
      organizationName: [this.organization?.name || null, Validators.required],
      firstName: [null, this.organizationId ? null : Validators.required],
      lastName: [null, this.organizationId ? null : Validators.required],
      dongleIds: this.fb.array([]),
      isLevel2: [this.organization?.isLevel2 || false],
      activeSubscription: [this.organization?.activeSubscription || false],
      complimentarySubscription: [this.organization?.complimentarySubscription || false],
      trialExpires: [new Date(this.organization?.trialExpires)],
      complimentarySubscriptionEndDate: [this.organization?.complimentarySubscriptionEndDate ? new Date(this.organization?.complimentarySubscriptionEndDate) : null],
      email: [null, this.organizationId ? [] : [Validators.required, Validators.email]],
      featuredInGlobalMarketplace : [this.organization?.featuredInGlobalMarketplace || false],
      marketplaceOrganizationPercentage: [this.organization?.marketplaceOrganizationPercentage || null, [Validators.min(0), Validators.max(1)]],
    });

    this.loadDongles();

    this.complimentarySubscriptionChanged();

    this.initialized = true;
  }

  complimentarySubscriptionChanged()
  {
    if (this.formGroup.controls.complimentarySubscription.value === 'true' || this.formGroup.controls.complimentarySubscription.value === true) {
      this.formGroup.controls.complimentarySubscriptionEndDate.enable();
    }
    else {
      this.formGroup.controls.complimentarySubscriptionEndDate.reset();
      this.formGroup.controls.complimentarySubscriptionEndDate.disable();

    }
  }

  getOrgUsers() {
    this.organizationService.getOrgUsersByOrgId(this.organizationId).subscribe(result => {
      this.users = result.sort(dynamicSort('firstName'));
    });
  }

  loadDongles() {
    if (this.organization.dongleIds) {
      this.dongleIds = this.formGroup.get('dongleIds') as FormArray;
      this.organization.dongleIds.forEach((element) => {
        this.dongleIds.push(this.createDongleId(element));
      });
    }
  }

  addDongle() {
    this.dongleIds = this.formGroup.get('dongleIds') as FormArray;
    this.dongleIds.push(this.createDongleId(''));
  }

  removeDongleId(index: number): void {
    this.dongleIds = this.formGroup.get('dongleIds') as FormArray;
    this.dongleIds.removeAt(index);
  }

  createDongleId(dongleId): FormGroup {
    return this.fb.group({
      dongleId: [dongleId, { validators: [Validators.required], asyncValidators: [this.validateDongleIdNotTaken.bind(this)], updateOn: 'change' }]
    });
  }

  cancel() {
    if (this.formGroup.dirty) {
      this.toastr.confirmDialog('Are you sure you want to discard changes?', 'Discard Changes').subscribe((result) => {
        if (result) {
          this.router.navigate([this.listUrl]);
        }
      });
    } else {
      this.router.navigate([this.listUrl]);
    }
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {

      // handle organization type mapping
      let organizationType: OrganizationType;
      let selectedTypes = this.organizationTypes.filter(x => x.selected);
        selectedTypes.forEach(day => {
          organizationType |= this.organizationTypeFlag[day.name];
        });

      if (this.organizationId) {

        let confirm;

        if (this.organization.runnerSlotSubscriptionId && (!this.organization.subscriptionEndDate || (this.organization.subscriptionEndDate && formatDate(this.organization.subscriptionEndDate, 'yyyy-MM-dd', 'en_US') > formatDate(new Date(), 'yyyy-MM-dd', 'en_US'))) && (this.formGroup.controls.complimentarySubscriptionEndDate.dirty || this.formGroup.controls.complimentarySubscription.dirty)) {
          confirm = this.toastr.confirmModal('<div class="text-danger font-weight-400"> You have updated the complimentary subscription data for the organization. Please note that the organization currently has an active Stripe subscription. Free trial period of the active Stripe subscription will be modified to align with the details of the new complimentary subscription. Please review the changes carefully before proceeding.</div>', 'Save Changes');
        }
        else {
          confirm = this.toastr.confirmDialog('Are you sure you want to save changes?', 'Save Changes');
        }

        confirm.subscribe((result) =>
        {
          if (result) {
            this.update(organizationType, resetButton);
          } else {
            resetButton();
            return;
          }
        });

      } else {
        this.usersService.isEmailDuplicate(encodeURIComponent(this.formGroup.get('email').value), 0).subscribe((result) => {
          if (result) {
            this.toastr.confirmModal('This email address already exists. Are you sure you want to create a new org for it?', 'Duplicate Email').subscribe((confirmResult) => {
              if (confirmResult) {
                this.add(organizationType, resetButton);
              } else {
                resetButton();
                return;
              }
            });
          } else {
            this.add(organizationType, resetButton);
          }
        });
      }
    });
  }

  add(organizationType: OrganizationType, resetButton: () => any) {
    const formData: CreateOrganizationViewModel = this.formGroup.value;
    formData.dongleIds = this.formGroup.controls.dongleIds.value.map((x) => x.dongleId);
    formData.organizationType = organizationType;

    this.organizationService.addOrganization(formData).subscribe((result) => {
      this.toastr.success('Organization Added', 'Success');
      resetButton();
      this.router.navigate([this.listUrl]);
    });
  }

  update(organizationType: OrganizationType, resetButton: () => any) {
    const formData: OrganizationViewModel = Object.assign({}, this.organization, this.formGroup.value);
    formData.name = this.formGroup.get('organizationName').value;
    formData.dongleIds = this.formGroup.controls.dongleIds.value.map((x) => x.dongleId);
    formData.organizationType = organizationType;

    this.superUserService.updateOrganization(this.organizationId, formData).subscribe((result) => {
      this.toastr.success('Organization Updated', 'Success');
      resetButton();
      this.router.navigate([this.listUrl]);
    },
      (error) =>
      {
        this.toastr.error("The organization cannot be updated. Check logs.", 'Error');
        resetButton();
      });
  }

  addUser() {
    const modalRef = this.modalService.open(AdminUserAddEditModalComponent, { size: 'lg' });
    modalRef.componentInstance.selectedOrganizationId = this.organizationId;
    modalRef.componentInstance.user = null;
    modalRef.result.then(
      (result) => {},
      (reason) => {
        if (reason == 'saved') {
          this.getOrgUsers();
        }
      }
    );
  }

  onImpersonateUser(userId: number) {
    this.superUserService.impersonateUser(userId).subscribe(result => {
      this.auth.impersonateUser(result);
    });
  }

  onSendInvitation(user: UserProfileViewModel) {
    this.toastr.confirmDialog('Are you sure you want to re-send an invitation to ' + user.fullName + ' (' + user.email + ')?', 'Re-send Invitation').subscribe((result) => {
      if (result) {
        this.usersService.resendCoachWelcomeEmail(user.id).subscribe((results) => {
          this.toastr.success('Invitation email sent', 'Success');
        });
      }
    });
  }

  validateDongleIdNotTaken(control: AbstractControl) {
    return timer(500).pipe(
      switchMap(() => this.organizationService.isDongleIdDuplicate(this.organizationId || 0, control.value)),
      map((res)  => {
        return res ? { dongleIdTaken: true } : null;
      })
    );
  }
}
